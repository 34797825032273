import ChipComponentPercent from 'app/components/reusable/chipComponentPercent/ChipComponentPercent';
import i18n from 'locales/i18n';
import { useState } from 'react';

interface IDescriptionModal {
    open: boolean;
    description: string;
    header: React.ReactNode;
}

export function useDescriptionModal() {
    const [descriptionModal, setDescriptionModal] = useState({} as IDescriptionModal);

    const openDescriptionModal = (description: string, confidence: number, name: string) => {
        setDescriptionModal({
            description,
            header: (
                <div>
                    <h3 className='nexus-mb-1'>{i18n.t('tableToAuMapping.descriptionModalHeader') as string}</h3>
                    <div className='nexus-row nexus-middle-xs'>
                        <div className='nexus-mr-1'>{name} </div>
                        <div className='nexus-row nexus-middle-xs'>
                            <p className='nexus-mr-1'>| {i18n.t('tableToAuMapping.confidence') as string}: </p>
                            <ChipComponentPercent value={confidence} />
                        </div>
                    </div>
                </div>
            ),
            open: true,
        });
    };

    const closeDescriptionModal = () => {
        setDescriptionModal({ description: '', header: '', open: false });
    };

    return [closeDescriptionModal, descriptionModal, openDescriptionModal] as const;
}
