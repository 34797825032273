import { IFile, UploadFunctionProp, UserRole } from 'constants/commonExportedInterfaces';
import React, { useEffect, useState } from 'react';
import {
    clearAssessControlData,
    uploadAllPageFile,
    uploadAssessControlFileToBackend,
} from 'app/views/home/redux/homeActions';
import { getUserRole, retrieveAllPagesFile } from 'app/views/home/redux/homeSelectors';

import LoaderComponent from '../nds/loader/loader';
import SingleFileUploadComponent from '../nds/singleFileUpload/singleFileUpload';
import TitleBar from '../reusable/titleBar/TitleBar';
import UploadButtons from '../reusable/uploadButtons/UploadButtons';
import i18n from 'locales/i18n';
import { uploadComponentHeaders } from 'constants/constants';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';

const UploadAssessControl: React.FC = () => {
    const [uploadedDoc, setUploadedDoc] = useState<IFile | any>(null);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const allPagesFiles = useSelector(retrieveAllPagesFile);
    const userRole = useSelector<string>(getUserRole);

    useEffect(() => {
        if (!allPagesFiles.step4.length) setUploadedDoc(null);
        if (allPagesFiles.step4.length) setUploadedDoc(allPagesFiles.step4[0]);
    }, []);

    const clearUploadedFileInRedux = () => {
        dispatch(
            uploadAllPageFile({
                ...allPagesFiles,
                step4: [],
            }),
        );
    };

    const uploadAllFiles = () => {
        const formData = new FormData();
        dispatch(clearAssessControlData());

        formData.append('inputFile', uploadedDoc);
        setShowLoader(true);

        const payload: UploadFunctionProp = { docType: '', data: formData };

        dispatch(uploadAssessControlFileToBackend(payload)).then((data: any) => {
            if (data && data.payload) {
                dispatch(
                    uploadAllPageFile({
                        ...allPagesFiles,
                        step4: [uploadedDoc],
                    }),
                );
            }
            setShowLoader(false);
        });
    };

    const handleSubmit = () => {
        uploadedDoc && uploadAllFiles();
    };

    const removeAllFiles = () => {
        setUploadedDoc(null);
        dispatch(uploadAllPageFile({ ...allPagesFiles, step4: [] }));

        dispatch(clearAssessControlData());
    };

    const isAnalyst = userRole === UserRole.ANALYST;
    return isAnalyst ? (
        <div data-testid='upload-file-component' className='upload-file'>
            <TitleBar title={uploadComponentHeaders.step4} />
            <div className='upload-file-styles nexus-row nexus-p-2 nexus-table'>
                <SingleFileUploadComponent
                    label={i18n.t<string>('uploadComponent.chooseFile')}
                    fileSizeInfo={i18n.t<string>('uploadComponent.fileSize')}
                    uploadedDoc={uploadedDoc}
                    setUploadedDoc={setUploadedDoc}
                    additionalFnOnRemoveFile={clearUploadedFileInRedux}
                />

                <UploadButtons upload={handleSubmit} clear={removeAllFiles} />
            </div>
            {showLoader && <LoaderComponent show={showLoader} fullscreen={false} />}
        </div>
    ) : null;
};

export default UploadAssessControl;
