import {
    ASIC,
    CFR,
    DefaultSelect,
    Generic,
    UploadComponentType,
    asic,
    //categoryDropdownObligation,
    cfr,
    fileTypeDropdown,
    generic,
    uploadComponentHeaders,
    /*categoryDropdownObligationPolicy,
    categoryDropdownObligationRegulatory,
    categoryDropdownObligationProcedure,*/
    DefaultSelectDocumentType,
    DefaultSelectProcessType,
    UploadedDocumentText,
} from 'constants/constants';
import { IFile, UploadFunctionProp, UserRole } from 'constants/commonExportedInterfaces';
import { NexusAccordion, NexusAccordionTrigger } from '@nexus/react';
import React, { useEffect, useState } from 'react';
import {
    clearObligationsData,
    selectedHomeCategory,
    selectedHomeTypeFile,
    uploadAllPageFile,
    uploadObligationFileToBackend,
} from 'app/views/home/redux/homeActions';
import { getUserRole, retrieveAllPagesFile } from 'app/views/home/redux/homeSelectors';

import LoaderComponent from '../nds/loader/loader';
import Select from '../nds/select/select';
import SingleFileUploadComponent from '../nds/singleFileUpload/singleFileUpload';
// import TitleBar from '../reusable/titleBar/TitleBar';
import UploadButtons from '../reusable/uploadButtons/UploadButtons';
import i18n from 'locales/i18n';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import './uploadObligation.scss';

const UploadObligation: React.FC = () => {
    const [uploadedDoc, setUploadedDoc] = useState<IFile | any>(null);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [showUpload, setShowUpload] = useState<boolean>(true);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const allPagesFiles = useSelector(retrieveAllPagesFile);
    const userRole = useSelector<string>(getUserRole);

    const [selectHomeCategory, setSelectHomeCategory] = useState<string>(DefaultSelectProcessType);
    const [fileTypeHome, setFileTypeHome] = useState<string>(DefaultSelectDocumentType);
    const [uploadProgress, setUploadProgress] = useState<string>('Not started');

    useEffect(() => {
        if (!allPagesFiles.home.length) setUploadedDoc(null);
        if (allPagesFiles.home.length) setUploadedDoc(allPagesFiles.home[0]);
        setShowLoader(false);
    }, []);

    const clearUploadedFileInRedux = () => {
        dispatch(
            uploadAllPageFile({
                ...allPagesFiles,
                home: [],
            }),
        );
    };

    const uploadAllFiles = () => {
        const formData = new FormData();
        dispatch(clearObligationsData());

        dispatch(selectedHomeTypeFile(fileTypeHome));
        dispatch(selectedHomeCategory(selectHomeCategory));
        formData.append('fileToPredict', uploadedDoc);
        formData.append('flow_', 'ui_flow');
        formData.append('draft_obligation_filename', uploadedDoc.name);
        formData.append('draft_obligation_filesize', uploadedDoc.size.toString());
        //setShowLoader(true);
        setUploadProgress('In progress');

        const payload: UploadFunctionProp = { docType: changeFileTypeCasing() ?? '', data: formData };

        dispatch(uploadObligationFileToBackend(payload)).then((data: any) => {
            if (data && data.payload) {
                setUploadProgress('Completed');
                setShowUpload(false);
                setDataLoaded(true);
                dispatch(
                    uploadAllPageFile({
                        ...allPagesFiles,
                        home: [uploadedDoc],
                    }),
                );
            }
            // setShowLoader(false);
        });
    };

    const handleSubmit = () => {
        uploadedDoc && uploadAllFiles();
    };

    const removeAllFiles = () => {
        setUploadedDoc(null);
        dispatch(uploadAllPageFile({ ...allPagesFiles, home: [] }));

        setFileTypeHome(DefaultSelect);
        setSelectHomeCategory(DefaultSelectDocumentType);
        dispatch(clearObligationsData());
        setFileTypeHome('Please Select Document Type');
        setSelectHomeCategory('Please Select Process Type');
        setUploadProgress('Not started');
    };

    /*const handleCategoryValue = () => {
        return selectHomeCategory;
    };

    const handleCategory = (value: string) => {
        setSelectHomeCategory(value);
    };*/

    const handleFileType = (value: string) => {
        setFileTypeHome(value);
    };

    const changeFileTypeCasing = () => {
        switch (fileTypeHome) {
            case CFR:
                return cfr;
            case ASIC:
                return asic;
            case Generic:
                return generic;
        }
    };

    /* const categoryDropdownDynamic = () => {
        switch (fileTypeHome) {
            case 'Regulatory':
                return categoryDropdownObligationRegulatory;
            case 'Policy':
                return categoryDropdownObligationPolicy;
            case 'Procedure':
                return categoryDropdownObligationProcedure;
        }
        return categoryDropdownObligation;
    };*/

    const activateUpload = () => {
        const isUploadButtonActive =
            uploadedDoc && fileTypeHome !== 'Please Select Document Type' && uploadProgress !== 'In progress';
        return !isUploadButtonActive;
    };

    const showFileUploadComponent = () => {
        setShowUpload(true);
        showUpload && setShowUpload(false);
    };

    const isAnalyst = userRole === UserRole.ANALYST;
    return isAnalyst ? (
        <>
            <div data-testid='upload-file-component' className='upload-file'>
                <NexusAccordion open={showUpload}>
                    <NexusAccordionTrigger onClick={() => showFileUploadComponent()}>
                        <h1 className='nexus-body'>
                            <b>{uploadComponentHeaders.home}</b>
                        </h1>
                    </NexusAccordionTrigger>
                </NexusAccordion>
                {/* <TitleBar title={uploadComponentHeaders.home} /> */}
                {showUpload && (
                    <div className='upload-file-styles nexus-row nexus-p-2 nexus-table'>
                        <SingleFileUploadComponent
                            label={i18n.t<string>('uploadComponent.chooseFile')}
                            fileSizeInfo={i18n.t<string>('uploadComponent.fileSize')}
                            uploadedDoc={uploadedDoc}
                            setUploadedDoc={setUploadedDoc}
                            additionalFnOnRemoveFile={clearUploadedFileInRedux}
                            clear={removeAllFiles}
                            isProgressVisible={true}
                            uploadProgress={uploadProgress}
                        />

                        <Select
                            // classes={
                            //     'nexus-ml-2 nexus-col-lg-3 nexus-col-xl-3 nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-my-2'
                            // }
                            classes={
                                'nexus-col-lg-3 nexus-col-xl-3 nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 select-styles'
                            }
                            options={fileTypeDropdown}
                            customOnChange={handleFileType}
                            selectedValue={fileTypeHome}
                            selectedType={UploadComponentType}
                            defaultPlaceholder={DefaultSelectDocumentType}
                            label='Document Type'
                        />
                        {/*<Select
                            classes={
                                'nexus-ml-2 nexus-col-lg-3 nexus-col-xl-3 nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 select-styles'
                            }
                            // options={categoryDropdownObligation}
                            options={categoryDropdownDynamic()}
                            customOnChange={handleCategory}
                            selectedValue={handleCategoryValue()}
                            selectedType={UploadComponentType}
                            defaultPlaceholder={DefaultSelectProcessType}
                            label='Process Type'
                        />*/}

                        <UploadButtons upload={handleSubmit} disabledValue={activateUpload()} clear={removeAllFiles} />
                    </div>
                )}
                {showLoader && <LoaderComponent show={showLoader} fullscreen={false} />}
            </div>
            {dataLoaded && (
                <>
                    <div className='padding-label'>
                        <span className='header-text'> {UploadedDocumentText} </span>
                        {uploadedDoc?.name}
                        <div className='align-right'>
                            <span className='header-text'> Document Type: </span>
                            <span>Policy</span>
                        </div>
                    </div>
                    <hr />
                </>
            )}
        </>
    ) : null;
};

export default UploadObligation;
