import './selectStatusButton.scss';

import { NexusIcon } from '@nexus/react';

interface ISelectStatusButton {
    handleClick: () => void;
    handleOnKeyDown: (e: React.KeyboardEvent<HTMLElement>) => boolean;
    status?: string;
    type: string;
    icon: React.ReactNode;
}

/**
 * SelectStatusButton component
 * @function handleClick set status approve or reject
 * @function handleOnKeyDown detect key press and handleClick
 * @param status current status data
 * @param type type used for testid, scss
 * @param icon use icon to display
 *
 */

const SelectStatusButton: React.FC<ISelectStatusButton> = ({
    handleOnKeyDown,
    handleClick,
    status,
    type,
    icon,
}: ISelectStatusButton) => {
    const handleKeyboardEvent = (e: React.KeyboardEvent<HTMLElement>) => {
        if (handleOnKeyDown(e)) handleClick();
    };

    return (
        <div data-testid={`${type}-icon`}>
            <NexusIcon
                tabIndex={0}
                onKeyDown={handleKeyboardEvent}
                data-testid={`${type}-nexus-icon`}
                size='md'
                src={`${icon}`}
                className={`button-style nexus-ml-2 ${
                    status?.length ? `${type.toLowerCase()}-${status.toLowerCase()}` : 'no-chose'
                }`}
                onClick={handleClick}
            />
        </div>
    );
};

export default SelectStatusButton;
