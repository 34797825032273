import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';

export interface IDocument {
    name: string;
    docType: string;
}

export const selectAuMapping = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.auMappingData : 'There is no data'),
);

export const selectAuMappingToSave = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.auMappingToSave : 'There is no data'),
);

export const selectObligation = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.obligationData : 'There is no data'),
);

export const getReviewerData = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.reviewerData : 'There is no data'),
);

export const retrieveAllDocumentsResponse = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.allDocsResponse : 'There is no data'),
);

export const retrieveAllPagesFile = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.allPagesFile : 'There is no data'),
);
export const retrievePagination = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.displayPaginationExternally : 'There is no data'),
);

export const takeHomeDocumentsData = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => {
        const homeDocuments: IDocument[] = [];
        homeData.allPagesFile.home.forEach((document: any) =>
            homeDocuments.push({ name: document.name, docType: homeData.homeCategoryDetails }),
        );
        return homeDocuments;
    },
);

export const takeStep1DocumentsData = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => {
        const step1Documents: IDocument[] = [];
        homeData.allPagesFile.step2.forEach((document: any) =>
            step1Documents.push({ name: document.name, docType: homeData.step1CategoryDetails }),
        );
        return step1Documents;
    },
);

export const getTextAreaData = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.textAreaData : 'There is no data'),
);

export const getEditSumText = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.editSumText : 'There is no data'),
);

export const getFilesResponse = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.allDocsResponse.home : 'There is no data'),
);

export const getSelectedHomeCategory = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.homeCategoryDetails : 'There is no data'),
);

export const retrieveHashValue = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.hashValue : 'There is no data'),
);

export const getUserRole = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.userRole : 'There is no data'),
);

export const selectNotificationsData = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.notificationsData : []),
);

export const retrieveApproverData = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.approverData : 'There is no data'),
);

export const selectSavedObligationData = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.savedObligationData : []),
);

export const selectObligationsToSave = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.obligationsToSave : []),
);

export const selectAssessControl = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.assessControlData : 'There is no data'),
);

export const selectAssessControlsToSave = createSelector(
    (state: RootState) => state.homeData,
    (homeData) => (homeData ? homeData.assessControlsToSave : []),
);
