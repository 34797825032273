import * as React from 'react';

import { StatusChose, UserRole } from 'constants/commonExportedInterfaces';

import ApproveNexusIcon from '@nexus/core/dist/assets/icons/navigation/ic_check_24px.svg';
import RejectNexusIcon from '@nexus/core/dist/assets/icons/navigation/ic_close_24px.svg';
import SelectStatusButton from '../selectStatusButton/SelectStatusButton';
import { getUserRole } from 'app/views/home/redux/homeSelectors';
import { isSpaceBarPressed } from 'services/isKeyPressed';
import { updateStatusInObligationsToAuTable } from 'app/views/home/redux/newHomeObligation.reducer';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';

interface IButtonsComponentSelectStatus {
    uniqueKeyID: string;
    statusAnalyst: string;
    statusReviewer: string;
}

const ButtonsComponentSelectStatus: React.FC<IButtonsComponentSelectStatus> = ({
    uniqueKeyID,
    statusAnalyst,
    statusReviewer,
}) => {
    const userRole = useSelector(getUserRole);
    const dispatch = useAppDispatch();
    const handleIconValue = (value: string, uniqueKeyID: string, statusBy: string) => {
        dispatch(updateStatusInObligationsToAuTable({ statusBy, uniqueKeyID, value }));
    };
    const statusBy = userRole === UserRole.ANALYST ? 'obligation_status_by_analyst' : 'obligation_status_by_reviewer';
    const currentStatus = userRole === UserRole.ANALYST ? statusAnalyst : statusReviewer;
    return (
        <div className='nexus-row' data-testid={uniqueKeyID}>
            <SelectStatusButton
                icon={ApproveNexusIcon}
                status={currentStatus}
                type={StatusChose.APPROVED}
                handleClick={() => handleIconValue(StatusChose.APPROVED, uniqueKeyID, statusBy)}
                handleOnKeyDown={isSpaceBarPressed}
            />
            <SelectStatusButton
                icon={RejectNexusIcon}
                status={currentStatus}
                type={StatusChose.REJECTED}
                handleClick={() => handleIconValue(StatusChose.REJECTED, uniqueKeyID, statusBy)}
                handleOnKeyDown={isSpaceBarPressed}
            />
        </div>
    );
};

export default ButtonsComponentSelectStatus;
