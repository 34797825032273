import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './app/App';
import './index.scss';

/** Beginning with React 18, when in development mode, the components will be mounted, unmounted, and then mounted once again in StrictMode. */
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
reportWebVitals();
