import { NexusTooltip, NexusTooltipContent, NexusTooltipTrigger } from '@nexus/react';
import React, { ReactNode } from 'react';

interface InputProps {
    message: any;
    children: ReactNode;
    placement?: '' | 'top' | 'bottom' | 'left' | 'right' | undefined;
    allowClick?: boolean;
    attrId?: string;
    className?: string;
}

const TooltipComponent: React.FC<InputProps> = ({ message, children, placement, allowClick, attrId, className }) => {
    return (
        <NexusTooltip
            placement={placement}
            className={className}
            allow-click={allowClick}
            data-testid={'nexus-tooltip'}
            id={attrId}
        >
            <NexusTooltipTrigger>{children}</NexusTooltipTrigger>

            <NexusTooltipContent
                data-testid={'nexus-tooltip-content'}
                className={className}
                dangerouslySetInnerHTML={{ __html: message }}
            ></NexusTooltipContent>
        </NexusTooltip>
    );
};

export default TooltipComponent;
