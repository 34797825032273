import { NexusNotification } from '@nexus/react';

interface InputProps {
    message: string;
    variant: 'success' | 'warning' | 'error' | 'info';
}

const NotificationComponent = (props: InputProps) => (
    <>
        <div className='nexus-m-4'>
            {props.variant === 'info' ? (
                <NexusNotification data-testid='nexus-notification-info'>{props.message}</NexusNotification>
            ) : (
                <NexusNotification data-testid='nexus-notification' variant={props.variant}>
                    {props.message}
                </NexusNotification>
            )}
        </div>
    </>
);

export default NotificationComponent;
