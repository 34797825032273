import './uploadButtons.scss';

import { Clear, Upload } from 'constants/constants';

import ButtonComponent from 'app/components/nds/button/button';
import React from 'react';

interface IUploadButtons {
    upload: () => void;
    clear: () => void;
    disabledValue?: boolean;
}

/**
 * UploadButtons component
 * @function upload handle submit function to upload file
 * @function clear remove file and clear data
 */

const UploadButtons: React.FC<IUploadButtons> = ({ upload, clear, disabledValue }) => {
    return (
        <div className='nexus-p-2 buttons' data-testid='buttons-wrapper'>
            <ButtonComponent click={clear} type={['nexus-btn-primary ']} label={Clear} />
            <ButtonComponent
                click={upload}
                type={['nexus-btn-primary nexus-ml-1']}
                label={Upload}
                disabled={disabledValue}
            />
        </div>
    );
};

export default UploadButtons;
