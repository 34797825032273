import React, { useState } from 'react';

import { NexusLabel, NexusSelect } from '@nexus/react';
import { UploadComponentType } from 'constants/constants';
// import i18n from 'locales/i18n';

interface InputProps {
    attrId?: string;
    delimiter?: string;
    disabled?: boolean;
    multiple?: boolean;
    required?: boolean;
    value?: string;
    type?: 'custom' | 'native';
    selectedValue?: string;
    classes?: string;
    options: {
        label: string;
        value: string;
        disabled?: boolean;
    }[];
    customOnChange?: (nextValue?: any) => void;
    selectedType?: string;
    defaultPlaceholder?: string;
    label?: string;
}

const Select: React.FC<InputProps> = (props: InputProps) => {
    const [value, setValue] = useState(props.selectedValue ? props.selectedValue : '');

    const onChangeOpts = (value: string) => {
        setValue(value);
        if (props.customOnChange) {
            props.customOnChange(value);
        }
    };
    return (
        <div>
            <NexusLabel>{props.label}</NexusLabel>
            <NexusSelect
                value={props.selectedType === UploadComponentType ? props.selectedValue : value}
                type={props.type}
                attrId={props.attrId}
                disabled={props.disabled}
                multiple={props.multiple}
                required={props.required}
                delimiter={props.delimiter}
                onInput={(event: any) => onChangeOpts(event.target.value)}
                data-testid='nexus-select'
                className={props.classes ? props.classes : ''}
            >
                {/* <option disabled>{i18n.t<string>('nds.pleaseSelect')}</option> */}
                <option disabled>{props.defaultPlaceholder}</option>
                {props.options.map((option, index) => {
                    return (
                        <option
                            data-testid='nexus-select-option'
                            value={option.value}
                            key={index}
                            disabled={option.disabled}
                        >
                            {option.label}
                        </option>
                    );
                })}
            </NexusSelect>
        </div>
    );
};

export default Select;
