import './singleFileUpload.scss';

// import { NexusFileUpload, NexusFormField, NexusChipGroup, NexusChip } from '@nexus/react';
import { NexusFileUpload, NexusFormField, NexusChipGroup, NexusChip } from '@nexus/react';
import ProgressBar from '../../nds/progressbar/progress';
import React, { useEffect, useState } from 'react';

interface ISingleFileUploadComponent {
    label: string;
    fileSizeInfo: string;
    uploadedDoc: any;
    setUploadedDoc: React.Dispatch<React.SetStateAction<any>>;
    additionalFnOnRemoveFile?: any;
    isProgressVisible?: boolean;
    uploadProgress?: string;
    disabled?: boolean;
    clear?: () => void;
}

let clearTimer: number;

const SingleFileUploadComponent: React.FC<ISingleFileUploadComponent> = ({
    label,
    fileSizeInfo,
    uploadedDoc,
    setUploadedDoc,
    // additionalFnOnRemoveFile,
    disabled,
    clear,
    isProgressVisible,
    uploadProgress,
}) => {
    // const removeFile = () => {
    //     setUploadedDoc(null);
    //     if (additionalFnOnRemoveFile) additionalFnOnRemoveFile();
    // };

    // const renderFile = (file: any, callback: any) => (
    //     <NexusChip
    //         data-testid='nexus-single-upload-chip'
    //         aria-label={`Click to remove file - ${file.name}`}
    //         removable={true}
    //         key={file.name}
    //         success={file.uploaded}
    //         onClick={() => callback(file)}
    //     >
    //         {file.name}
    //     </NexusChip>
    // );

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (uploadProgress === 'In progress') {
            let count = 0;
            clearTimer = window.setInterval(() => {
                if (count < 60) {
                    count = count + 1;
                    setProgress(count);
                }
            }, 20);
        } else if (uploadProgress === 'Completed') {
            window.clearInterval(clearTimer);
            setProgress(100);
        } else {
            setProgress(0);
        }

        return () => {
            window.clearInterval(clearTimer);
        };
    }, [uploadProgress]);

    return (
        <>
            <>
                The file must be .pdf and not exceed 25mb<span style={{ color: 'red' }}>*</span>
            </>
            <div className='upload-box'>
                <NexusFormField>
                    <NexusFileUpload
                        style={{ height: '132px', top: '5px' }}
                        data-testid='nexus-single-file-upload'
                        onInput={(event: any) => {
                            setProgress(0);
                            setUploadedDoc(event.target.files[0]);
                        }}
                        onDrop={(event: any) => setUploadedDoc(event.dataTransfer.files[0])}
                        disabled={disabled}
                    >
                        <p>{label}</p>
                        <p>{fileSizeInfo}</p>
                    </NexusFileUpload>
                </NexusFormField>
                {isProgressVisible && uploadProgress !== 'Not started' && <ProgressBar value={progress} />}

                {uploadedDoc && (
                    <NexusChipGroup data-testid='nexus-single-upload-chip-group' className='nexus-rhythm-4'>
                        {/* span required for react to render dynamic content into the correct slot */}
                        <NexusChip
                            removable={true}
                            onTriggerRemovableClick={clear}
                            data-testid='nexus-single-upload-chip'
                            success={uploadProgress === 'Completed'}
                        >
                            {uploadedDoc.name}
                        </NexusChip>
                        {/* <span>{renderFile(uploadedDoc, removeFile)}</span> */}
                    </NexusChipGroup>
                )}
            </div>
        </>
    );
};

export default SingleFileUploadComponent;
