import * as ACTIONS from './homeActions';
import * as _ from 'lodash';

import {
    IAllDocumentsData,
    IAssessControls,
    IAuMappingData,
    IObligationData,
    IObligations,
    ITimeNotification,
    IUploadDocuments,
    SavedObligationData,
    UserRole,
    VariantChose,
} from 'constants/commonExportedInterfaces';
import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { DefaultSelect, DefaultSelectDocumentType } from 'constants/constants';
import i18n from 'locales/i18n';

interface IAuMappingResult {
    result: IAuMappingData[];
}
interface IObligationResult {
    obligations: IObligationData;
}
interface IAssessControlResult {
    result: IAssessControls[];
}
interface IHomeSliceReducer {
    allDocsResponse: IAllDocumentsData;
    allPagesFile: IAllDocumentsData;
    auMapping: IAuMappingResult;
    auMappingToSave: IAuMappingData[];
    assessControlData: IAssessControlResult;
    assessControlsToSave: IAssessControls[];
    editSumText: string;
    globalLoading: boolean;
    hashValue: number;
    homeCategoryDetails: string;
    homeTypeDetails: string;
    notificationsData: ITimeNotification[];
    obligationData: IObligationResult;
    obligationsToSave: IObligations[];
    savedObligationData: SavedObligationData[];
    step1CategoryDetails: string;
    textAreaData: string;
    uploadedFiles: IUploadDocuments[];
    userRole: string;
    approverData: IObligationData;
    displayPaginationExternally: boolean;
}

export const INITIAL_STATE_HOME: IHomeSliceReducer = {
    allDocsResponse: { home: [], step2: [], step4: [] },
    allPagesFile: { home: [], step2: [], step4: [] },
    approverData: {} as IObligationData,
    assessControlData: {} as IAssessControlResult,
    assessControlsToSave: [] as IAssessControls[],
    auMapping: {} as IAuMappingResult,
    auMappingToSave: [] as IAuMappingData[],
    displayPaginationExternally: false,
    editSumText: '',
    globalLoading: false,
    hashValue: 0,
    homeCategoryDetails: DefaultSelectDocumentType,
    homeTypeDetails: DefaultSelectDocumentType,
    notificationsData: [] as ITimeNotification[],
    obligationData: {} as IObligationResult,
    obligationsToSave: [],
    savedObligationData: [],
    step1CategoryDetails: DefaultSelect,
    textAreaData: '',
    uploadedFiles: [],
    userRole: UserRole.ANALYST as UserRole,
};

type HomeState = Readonly<typeof INITIAL_STATE_HOME>;

export const homeSliceReducer = createSlice({
    extraReducers: (builder) =>
        builder
            .addCase(ACTIONS.clearAuMappingData, (state) => {
                return {
                    ...state,
                    auMapping: {} as IAuMappingResult,
                    auMappingToSave: [] as IAuMappingData[],
                };
            })
            .addCase(ACTIONS.uploadAuMappingFileToBackend.fulfilled, (state, action) => {
                return {
                    ...state,
                    auMappingToSave: action.payload?.data.result,
                    savedAuMappingData: action.payload?.data,
                };
            })
            .addCase(ACTIONS.getSavedMapAu.fulfilled, (state, action) => {
                const indexOfLast = action.payload?.data.length - 1;
                return {
                    ...state,
                    auMappingData: action.payload?.data,
                    auMappingToSave: action.payload?.data[indexOfLast]?.aumapping,
                };
            })
            .addCase(ACTIONS.clearObligationsData, (state) => {
                return {
                    ...state,
                    obligationData: {} as IObligationResult,
                    obligationsToSave: [] as IObligations[],
                };
            })
            .addCase(ACTIONS.uploadObligationFileToBackend.fulfilled, (state, action) => {
                return {
                    ...state,
                    approverData: action.payload?.data,
                    //* Hash should be given from backend after DEMO */
                    hashValue: Math.floor(Math.random() * 9999999) + 1,
                    obligationData: action.payload?.data,
                    obligationsToSave: action.payload?.data,
                };
            })
            .addCase(ACTIONS.getSavedObligation.fulfilled, (state, action) => {
                const indexOfLast = action.payload?.data.length - 1;
                action.payload?.data[indexOfLast]?.draftobg.forEach((snap: IObligations) => delete snap.obligation_id);
                return {
                    ...state,
                    // TODO ensure why data is an array
                    obligationsToSave: action.payload?.data[indexOfLast].draftobg,
                    savedObligationData: action.payload?.data,
                };
            })
            .addCase(ACTIONS.uploadAssessControlFileToBackend.fulfilled, (state, action) => {
                return {
                    ...state,
                    assessControlData: action.payload?.data,
                    assessControlsToSave: action.payload?.data.result,
                };
            })
            .addCase(ACTIONS.getSavedAssessControl.fulfilled, (state, action) => {
                const indexOfLast = action.payload?.data.length - 1;
                return {
                    ...state,
                    assessControlsToSave: action.payload?.data[indexOfLast].validate5ws,
                    savedAssessControl: action.payload?.data,
                };
            })
            .addCase(ACTIONS.clearAssessControlData, (state) => {
                return {
                    ...state,
                    assessControlData: {} as IAssessControlResult,
                    assessControlsToSave: [] as IAssessControls[],
                };
            })
            .addCase(ACTIONS.storeTextAreaValue, (state, action) => {
                return {
                    ...state,
                    textAreaData: action.payload,
                };
            })
            .addCase(ACTIONS.storeEditSumText, (state, action) => {
                return {
                    ...state,
                    editSumText: action.payload,
                };
            })
            .addCase(ACTIONS.addReviewerData, (state, action) => {
                return {
                    ...state,
                    reviewerData: action.payload,
                };
            })

            .addCase(ACTIONS.selectedHomeCategory, (state, action) => {
                return {
                    ...state,
                    homeCategoryDetails: action.payload,
                };
            })
            .addCase(ACTIONS.selectedHomeTypeFile, (state, action) => {
                return {
                    ...state,
                    homeTypeDetails: action.payload,
                };
            })
            .addCase(ACTIONS.selectedStep1Category, (state, action) => {
                return {
                    ...state,
                    step1CategoryDetails: action.payload,
                };
            })
            .addCase(ACTIONS.updateUserRole, (state, action) => {
                return {
                    ...state,
                    userRole: action.payload,
                };
            })
            .addCase(ACTIONS.uploadAllPageFile, (state, action) => {
                return {
                    ...state,
                    allPagesFile: action.payload,
                };
            })
            .addCase(ACTIONS.addNotificationData, (state, action) => {
                return {
                    ...state,
                    notificationsData: action.payload,
                };
            })
            .addCase(ACTIONS.clearNotifications, (state) => {
                return {
                    ...state,
                    notificationsData: [],
                };
            })
            .addCase(ACTIONS.saveObligationData, (state, action) => {
                const data = _.uniqBy(action.payload, action.payload[action.payload.length - 1].run_id);
                return {
                    ...state,
                    savedObligationData: data,
                };
            })
            .addCase(ACTIONS.setPaginationExternally, (state, action) => {
                return {
                    ...state,
                    displayPaginationExternally: action.payload,
                };
            })
            .addCase(ACTIONS.updateStatusInObligationsToSave, (state, action) => {
                const option = action.payload.user === UserRole.REVIEWER ? 'status' : 'status_to_be_reviewed';
                return {
                    ...state,
                    obligationsToSave: state.obligationsToSave.map((obligation) => {
                        if (obligation.obligation_name === action.payload.name)
                            return { ...obligation, [option]: action.payload.status };
                        return obligation;
                    }),
                };
            })
            /** Change this "au_name" when back-end give us Unique ID */
            .addCase(ACTIONS.updateStatusInAuToSave, (state, action) => {
                const option = action.payload.user === UserRole.REVIEWER ? 'status' : 'status_to_be_reviewed';
                return {
                    ...state,
                    auMappingToSave: [
                        ...state.auMappingToSave.map((item) => {
                            if (item.obligation_name !== action.payload.detectOpenRow) {
                                // This isn't the item we care about - keep it as-is
                                return item;
                            }
                            // Otherwise, this is the one we want - return an updated value
                            return {
                                ...item,
                                applicable_aus: [
                                    ...item.applicable_aus.map((applicableAus) => {
                                        if (applicableAus.au_name !== action.payload.name) return applicableAus;
                                        return {
                                            ...applicableAus,
                                            [option]: action.payload.status,
                                        };
                                    }),
                                ],
                            };
                        }),
                    ],
                };
            })
            .addCase(ACTIONS.updateStatusInAssessControlsToSave, (state, action) => {
                const option = action.payload.user === UserRole.REVIEWER ? 'status' : 'status_to_be_reviewed';
                return {
                    ...state,
                    assessControlsToSave: state.assessControlsToSave.map((controls) => {
                        if (controls.ctrl_id === action.payload.ctrl_id)
                            return { ...controls, [option]: action.payload.status };
                        return controls;
                    }),
                };
            })
            .addCase(ACTIONS.updateEditSumText, (state, action) => {
                return {
                    ...state,
                    obligationsToSave: state.obligationsToSave.map((obligation) => {
                        if (obligation.obligation_name === action.payload.name)
                            return { ...obligation, obligation_summary_text: action.payload.sum_text };
                        return obligation;
                    }),
                };
            })
            .addCase(ACTIONS.updateTooltipContent, (state, action) => {
                return {
                    ...state,
                    obligationsToSave: state.obligationsToSave.map((obligation) => {
                        if (obligation.obligation_name === action.payload.name)
                            return { ...obligation, action_summary: action.payload.action_summary };
                        return obligation;
                    }),
                };
            })
            .addMatcher(
                isPending(
                    ACTIONS.uploadObligationFileToBackend,
                    ACTIONS.uploadAuMappingFileToBackend,
                    ACTIONS.uploadAssessControlFileToBackend,
                ),
                (state) => {
                    state.globalLoading = true;
                    state.notificationsData = [
                        ...state.notificationsData,
                        {
                            message: `${i18n.t('generalAnswersForUploadingDocumentAPICall.pending')}`,
                            time: 3000,
                            variant: VariantChose.INFO,
                        },
                    ];
                },
            )
            .addMatcher(
                isRejected(
                    ACTIONS.uploadObligationFileToBackend,
                    ACTIONS.uploadAuMappingFileToBackend,
                    ACTIONS.uploadAssessControlFileToBackend,
                ),
                (state, action) => {
                    state.globalLoading = false;
                    state.notificationsData = [
                        ...state.notificationsData,
                        {
                            message: `${i18n.t('generalAnswersForUploadingDocumentAPICall.reject')}  ${action.payload}`,
                            time: 5000,
                            variant: VariantChose.ERROR,
                        },
                    ];
                },
            )
            .addMatcher(
                isFulfilled(
                    ACTIONS.uploadObligationFileToBackend,
                    ACTIONS.uploadAuMappingFileToBackend,
                    ACTIONS.uploadAssessControlFileToBackend,
                ),
                (state) => {
                    state.globalLoading = false;
                    state.notificationsData = [
                        ...state.notificationsData,
                        {
                            message: `${i18n.t('generalAnswersForUploadingDocumentAPICall.fulfilled')}`,
                            time: 3000,
                            variant: VariantChose.SUCCESS,
                        },
                    ];
                },
            )
            .addMatcher(
                isFulfilled(ACTIONS.getSavedObligation, ACTIONS.getSavedMapAu, ACTIONS.getSavedAssessControl),
                (state) => {
                    state.globalLoading = true;
                    state.notificationsData = [
                        ...state.notificationsData,
                        {
                            message: `${i18n.t('generalAnswersForGettingDataForReviewer.loadingSavedDataSuccess')}`,
                            time: 5000,
                            variant: VariantChose.SUCCESS,
                        },
                    ];
                },
            )
            .addMatcher(
                isRejected(ACTIONS.getSavedObligation, ACTIONS.getSavedMapAu, ACTIONS.getSavedAssessControl),
                (state, action) => {
                    state.globalLoading = false;
                    state.notificationsData = [
                        ...state.notificationsData,
                        {
                            message: `${i18n.t('generalAnswersForGettingDataForReviewer.loadingSavedDataError')} ${
                                action.payload
                            }`,
                            time: 5000,
                            variant: VariantChose.ERROR,
                        },
                    ];
                },
            )
            .addMatcher(
                isFulfilled(
                    ACTIONS.saveAnalystObligations,
                    ACTIONS.saveReviewerObligations,
                    ACTIONS.saveAnalystControlMapping,
                    ACTIONS.saveAnalystAssessControl,
                ),
                (state) => {
                    state.globalLoading = true;
                    state.notificationsData = [
                        ...state.notificationsData,
                        {
                            message: `${i18n.t('generalAnswersForSavingDataCalls.success')}`,
                            time: 3000,
                            variant: VariantChose.SUCCESS,
                        },
                    ];
                },
            )
            .addMatcher(
                isRejected(
                    ACTIONS.saveAnalystObligations,
                    ACTIONS.saveReviewerObligations,
                    ACTIONS.saveAnalystControlMapping,
                    ACTIONS.saveAnalystAssessControl,
                ),
                (state, action) => {
                    state.globalLoading = false;
                    state.notificationsData = [
                        ...state.notificationsData,
                        {
                            message: `${i18n.t('generalAnswersForSavingDataCalls.error')} ${action.payload}`,
                            time: 3000,
                            variant: VariantChose.ERROR,
                        },
                    ];
                },
            ),
    initialState: INITIAL_STATE_HOME as HomeState,
    name: 'homeData',
    reducers: {},
});

export default homeSliceReducer.reducer;
