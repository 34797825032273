import React from 'react';
import './unAuthContainer.scss';

type Props = {
    children?: React.ReactNode;
};

const UnAuthContainer: React.FC<Props> = ({ children }) => (
    <div className='hero-image-login'>
        <div className='align-middle-login'>{children}</div>
    </div>
);

export default UnAuthContainer;
