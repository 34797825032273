import './descriptionComponentModal.scss';

import * as React from 'react';

import TooltipComponent from '../../nds/tooltip/tooltip';
import i18n from 'locales/i18n';

interface IDescriptionComponentModal {
    description: string;
    confidenceScore: number;
    buName: string;
    openDescriptionModal: (description: string, confidence: number, name: string) => void;
}

const DescriptionComponentModal: React.FC<IDescriptionComponentModal> = ({
    description,
    confidenceScore,
    buName,
    openDescriptionModal,
}) => {
    return (
        <div className='area-to-click' onClick={() => openDescriptionModal(description, confidenceScore, buName)}>
            <TooltipComponent allowClick={false} message={i18n.t('tableToAuMapping.tooltipText')} placement='bottom'>
                <span data-testid='description-to-click-tableToAuMapping' className='ellipsis'>
                    {description}
                </span>
            </TooltipComponent>
        </div>
    );
};

export default DescriptionComponentModal;
