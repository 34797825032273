import { NexusFormField, NexusTextarea } from '@nexus/react';

import React from 'react';

interface Props {
    attrId?: string;
    disabled?: boolean;
    maxLength?: number;
    minLength?: number;
    placeholder?: string;
    required?: boolean;
    value?: string;
    onInputHandler?: (e: React.FormEvent<HTMLNexusTextareaElement>) => void;
}
const TextareaComponent: React.FC<Props> = (props: Props) => (
    <NexusFormField>
        <NexusTextarea
            data-testid='nexus-textarea'
            attrId={props.attrId}
            disabled={props.disabled}
            maxLength={props.maxLength}
            minLength={props.minLength}
            placeholder={props.placeholder}
            required={props.required}
            value={props.value}
            onInput={(e) => props.onInputHandler?.(e)}
        ></NexusTextarea>
    </NexusFormField>
);

export default TextareaComponent;
