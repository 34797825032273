import { NexusCheckbox } from '@nexus/react';
import React from 'react';
import { obligationsHeaderColumnDefinition } from './tableHeaders';

interface ITableHeaderComponent {
    statusBy: string;
    checkedState: boolean[];
    checkedStateAll: boolean;
    setCheckedState: (value: boolean[]) => void;
    setCheckedStateAll: (value: boolean) => void;
    handleOnChangeAll: (
        checkedState: boolean[],
        checkedStateAll: boolean,
        setCheckedState: (value: boolean[]) => void,
        setCheckedStateAll: (value: boolean) => void,
    ) => void;
}

const TableHeaderComponent: React.FC<ITableHeaderComponent> = ({
    statusBy,
    checkedStateAll,
    handleOnChangeAll,
    checkedState,
    setCheckedState,
    setCheckedStateAll,
}) => {
    return (
        <tr slot='thead'>
            {obligationsHeaderColumnDefinition(statusBy).map((element) => (
                <th key={element.label} className='custom-thead-style'>
                    {element.field === 'select' && (
                        <NexusCheckbox
                            checked={checkedStateAll}
                            onInput={() =>
                                handleOnChangeAll(checkedState, checkedStateAll, setCheckedState, setCheckedStateAll)
                            }
                            data-testid='indeterminateValue'
                            className='nexus-rhythm-2'
                        />
                    )}
                    {element.label}
                </th>
            ))}
        </tr>
    );
};

export default TableHeaderComponent;
