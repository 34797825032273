import i18n from 'locales/i18n';

export interface IHeaderAuMapping {
    field: string;
    isSortable: boolean;
    label: string;
}

export const obligationsHeaderColumnDefinition = (statusBy: string): IHeaderAuMapping[] => {
    let columnsHeaderAnalyst = [
        {
            field: 'select',
            isSortable: false,
            label: '',
        },
        {
            field: 'obligation_id',
            isSortable: false,
            label: i18n.t('tableToAuMapping.obligationId'),
        },
        {
            field: 'obligation_title',
            isSortable: false,
            label: i18n.t('tableToAuMapping.obligationName'),
        },
        {
            field: 'obligation_description',
            isSortable: false,
            label: i18n.t('tableToAuMapping.obligationDescription'),
        },
        {
            field: 'bu_name',
            isSortable: false,
            label: i18n.t('tableToAuMapping.auName'),
        },
        {
            field: 'obligation_confidence_score',
            isSortable: false,
            label: i18n.t('tableToAuMapping.confidence'),
        },
        {
            field: statusBy,
            isSortable: false,
            label: i18n.t('tableToAuMapping.approveReject'),
        },
    ];

    let columnsHeaderReviewer = [
        ...columnsHeaderAnalyst,
        {
            field: 'obligation_status_by_analyst',
            isSortable: false,
            label: i18n.t('tableToAuMapping.analystStatus'),
        },
    ];

    return statusBy === 'obligation_status_by_analyst' ? columnsHeaderAnalyst : columnsHeaderReviewer;
};
