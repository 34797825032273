import i18n from 'locales/i18n';

interface IHeaderAssessControl {
    fieldId: string;
    label: string;
}

export const headerAnalyst: IHeaderAssessControl[] = [
    {
        fieldId: 'control type',
        label: i18n.t('assessControlTable.controlType'),
    },
    {
        fieldId: 'control description',
        label: i18n.t('assessControlTable.controlDescription'),
    },
    {
        fieldId: 'approve/reject',
        label: i18n.t('assessControlTable.approveReject'),
    },
    {
        fieldId: 'what',
        label: i18n.t('assessControlTable.what'),
    },
    {
        fieldId: 'when',
        label: i18n.t('assessControlTable.when'),
    },
    {
        fieldId: 'who',
        label: i18n.t('assessControlTable.who'),
    },
    {
        fieldId: 'where',
        label: i18n.t('assessControlTable.where'),
    },
    {
        fieldId: 'why',
        label: i18n.t('assessControlTable.why'),
    },
];

export const headerReviewer: IHeaderAssessControl[] = [
    {
        fieldId: 'control type',
        label: i18n.t('assessControlTable.controlType'),
    },
    {
        fieldId: 'control description',
        label: i18n.t('assessControlTable.controlDescription'),
    },
    {
        fieldId: 'analyst_status',
        label: i18n.t('assessControlTable.analystStatus'),
    },
    {
        fieldId: 'approve/reject',
        label: i18n.t('assessControlTable.approveReject'),
    },
    {
        fieldId: 'what',
        label: i18n.t('assessControlTable.what'),
    },
    {
        fieldId: 'when',
        label: i18n.t('assessControlTable.when'),
    },
    {
        fieldId: 'who',
        label: i18n.t('assessControlTable.who'),
    },
    {
        fieldId: 'where',
        label: i18n.t('assessControlTable.where'),
    },
    {
        fieldId: 'why',
        label: i18n.t('assessControlTable.why'),
    },
];
