import React from 'react';
import { NexusLoader } from '@nexus/react';

interface Props {
    show: boolean;
    fullscreen?: boolean;
}
const LoaderComponent: React.FC<Props> = ({ show, fullscreen }) => (
    <NexusLoader data-testid='nexus-loader' show={show} fullscreen={fullscreen}></NexusLoader>
);

export default LoaderComponent;
