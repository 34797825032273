import './chipComponentPercent.scss';

import * as React from 'react';

import ChipComponent from '../../nds/chip/chip';
import { getValuePercentage } from 'services/getPercentagevalue';
import { percentage } from 'constants/constants';

interface IChipComponentPercent {
    value: number;
}

const ChipComponentPercent: React.FC<IChipComponentPercent> = ({ value }) => {
    return (
        <div className='rcm-chips-styles'>
            <ChipComponent title={getValuePercentage(value) + percentage} contentStyles='rcm-chip-content-style' />
        </div>
    );
};

export default ChipComponentPercent;
