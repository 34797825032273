import { IObligationRow } from './ITableToAuMapping';
import React from 'react';
import TableAuObligationRow from './TableAuObligationRow';
import { limit } from 'constants/constants';

interface IBodyComponent {
    tableData: IObligationRow[];
    pagesVisited: () => number;
    statusBy: string;
}
const BodyComponent: React.FC<IBodyComponent> = ({ tableData, pagesVisited, statusBy }) => {
    return (
        <div slot='tbody'>
            {!!tableData?.length &&
                tableData
                    ?.slice(pagesVisited(), pagesVisited() + limit)
                    .map((obligation: IObligationRow) => (
                        <TableAuObligationRow
                            key={obligation.uniqueKeyID}
                            obligation={obligation}
                            statusBy={statusBy}
                        />
                    ))}
        </div>
    );
};

export default BodyComponent;
