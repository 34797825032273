import { IAuMappingData, IExpandedRow } from 'constants/commonExportedInterfaces';

import { ReactComponent as ArrowDown } from '@nexus/core/dist/assets/icons/hardware/ic_keyboard_arrow_down_24px.svg';
import { ReactComponent as ArrowUp } from '@nexus/core/dist/assets/icons/hardware/ic_keyboard_arrow_up_24px.svg';
import React from 'react';

interface IArrows {
    obligation: IAuMappingData;
    onClickRowChange: (name: string, isOpen: boolean) => void;
    expandedRow: IExpandedRow;
}

/**
 * Arrows component
 * @param obligation data
 * @function callback set if open/close row and arrow is down/up
 * @param expandedRow current row to be expanded
 */

const Arrows: React.FC<IArrows> = ({ obligation: { obligation_name }, onClickRowChange, expandedRow }) => {
    return (
        <>
            {expandedRow[obligation_name] ? (
                <ArrowUp
                    data-testid='arrow-up'
                    onClick={() => onClickRowChange(obligation_name, expandedRow[obligation_name] ? false : true)}
                />
            ) : (
                <ArrowDown
                    data-testid='arrow-down'
                    onClick={() => onClickRowChange(obligation_name, expandedRow[obligation_name] ? false : true)}
                />
            )}
        </>
    );
};

export default Arrows;
