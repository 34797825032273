import './App.scss';

import store, { persistor } from '../store';

import AppRoutes from './routes';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

/** Beginning with React 18, when in development mode, the components will be mounted, unmounted, and then mounted once again in StrictMode.
 * @remove <React.StrictMode>
 */
const App = () => {
    return (
        <div data-testid='nexus-app'>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <AppRoutes />
                </PersistGate>
            </Provider>
        </div>
    );
};

export default App;
