import './rejectIcon.scss';

import { NexusIcon } from '@nexus/react';
import RejectNexusIcon from '@nexus/core/dist/assets/icons/navigation/ic_close_24px.svg';
import { StatusChose } from 'constants/commonExportedInterfaces';

interface IRejectIcon {
    handleRejectIcon: () => void;

    status?: string;
}
const RejectIcon = ({ handleRejectIcon, status }: IRejectIcon) => {
    return (
        <>
            <div data-testid='reject-icon'>
                <NexusIcon
                    data-testid='reject-nexus-icon'
                    size='md'
                    src={RejectNexusIcon}
                    className={`button-style nexus-ml-1 ${
                        status === StatusChose.REJECTED ? 'reject-button-selected' : 'reject-button-unselected'
                    }`}
                    onClick={handleRejectIcon}
                />
            </div>
        </>
    );
};
export default RejectIcon;
