import './homePage.scss';

// import { setPaginationExternally, updateUserRole } from './redux/homeActions';
import { stepperRoutes, steps } from 'constants/stepperConstants';
import { useEffect, useState } from 'react';

import AssessControlTable from 'app/components/assessControlTable/AssessControlTable';
import ControlsMapping from 'app/components/controlsMapping/ControlsMapping';
import ObligationSummary from 'app/components/obligationSummary/ObligationSummary';
import OverallSkeletonPane from 'app/views/overallSkeletonPane/OverallSkeletonPane';
import { Stepper } from '../../components/stepper/Stepper';
import TableToAuMapping from 'app/components/tableToAuMapping/TableToAuMapping';
import UploadAssessControl from 'app/components/uploadAssessControl/UploadAssessControl';
import UploadAuMapping from 'app/components/uploadAuMapping/UploadAuMapping';
import UploadObligation from 'app/components/uploadObligation/UploadObligation';
import UploadedDocuments from 'app/components/uploadedDocuments/UploadedDocuments';
// import UserProfileView from 'app/components/userProfileView/UserProfileView';
// import { UserRole } from 'constants/commonExportedInterfaces';
// import { useAppDispatch } from 'store';
// import { useLocation } from 'react-router';

const HomePage = () => {
    // const currentStep = useLocation().pathname.split('/')[2];
    const currentStep = 'home';
    const [activeStep, setActiveStep] = useState(currentStep);
    const [operationTop, setOperationTop] = useState<JSX.Element>(<></>);
    // const [userRole, setUserRole] = useState<string>(UserRole.ANALYST);
    const [operationsBottom, setOperationsBottom] = useState<JSX.Element>(<></>);
    // const dispatch = useAppDispatch();

    const handleStepClick = (currentStep: string) => {
        setActiveStep(currentStep);
    };

    useEffect(() => {
        switch (activeStep) {
            case stepperRoutes.Home:
                setOperationTop(<UploadObligation />);
                setOperationsBottom(<ObligationSummary />);
                break;
            case stepperRoutes.Step2:
                setOperationTop(<UploadAuMapping />);
                setOperationsBottom(<TableToAuMapping />);
                break;
            case stepperRoutes.Step3:
                setOperationTop(<UploadedDocuments />);
                setOperationsBottom(<ControlsMapping />);
                break;
            case stepperRoutes.Step4:
                setOperationTop(<UploadAssessControl />);
                setOperationsBottom(<AssessControlTable />);
                break;
        }
    }, [activeStep]);

    // const handleUserProfile = (value: string) => {
    //     // setUserRole(value);
    //     dispatch(updateUserRole(value));
    //     dispatch(setPaginationExternally(true));
    // };
    // const user = <UserProfileView handleUserProfile={(event) => handleUserProfile(event)} userRole={userRole} />;
    const navigation = <Stepper handleStepClick={handleStepClick} steps={steps} currentStep={activeStep} />;

    return (
        <div data-testid='nexus-home'>
            <OverallSkeletonPane
                // user={user}
                navigation={navigation}
                operationsTop={operationTop}
                operationsBottom={operationsBottom}
            />
        </div>
    );
};

export default HomePage;
