export enum StatusChose {
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
}

export enum UserRole {
    ANALYST = 'Analyst',
    REVIEWER = 'Reviewer',
}

export enum VariantChose {
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
}

/** Here we create/export interfaces that are used in 2 separate files */
export interface IObligations {
    confidence: number;
    justification: string;
    ob_id: number;
    obligation_highlighted_text: string;
    obligation_id?: string;
    obligation_name: string;
    obligation_summary_text: string;
    status: StatusChose;
    status_to_be_reviewed: StatusChose;
    action_summary: IActionSummary[];
    action_extract: IActionSummary[];
}

export interface IActionSummary {
    subject: string;
    object: string;
    action: string;
    jurisdiction: string;
}

export interface IObligationData {
    citation: string;
    obligations: IObligations[];
    regulation_id: string;
    regulation_name: string;
    source_url: string;
}

export interface ObligationStatus {
    detectOpenRow?: string;
    name: string;
    status: StatusChose;
    user: UserRole;
}

export interface EditedObligationSumText {
    name: string;
    sum_text: string;
}

export interface UpdateTooltipContent {
    name: string;
    action_summary: IActionSummary[];
}

export interface IApplicableAus {
    activity_description: string;
    au_id: string;
    au_name: string;
    confidence: number;
    justification: string;
    status: StatusChose | string;
    status_to_be_reviewed: StatusChose | string;
    ctrl_name?: string;
}

export interface IAuMappingData {
    applicable_aus: IApplicableAus[];
    ob_id: number;
    obligation_id: string;
    obligation_name: string;
}

export interface IAssessControls {
    Control_Description: string;
    Control_Name: string;
    What: string;
    What_Confidence_Score: number;
    When: string;
    When_Confidence_Score: number;
    Where: string;
    Where_Confidence_Score: number;
    Who: string;
    Who_Confidence_Score: number;
    Why: string;
    Why_Confidence_Score: number;
    ctrl_id: number;
    status: StatusChose;
    status_to_be_reviewed: StatusChose;
}

export interface AssessControlStatus {
    ctrl_id: number;
    status: StatusChose;
    user: UserRole;
}

export interface IStep {
    key?: string;
    label?: string;
    name: string;
}

export interface IExpandedRow {
    [key: string]: boolean;
}

export interface IUploadDocuments {
    name: string;
    size: number;
}

export interface ReviewerDataStep {
    approved: boolean;
    text: string[];
}

export interface ReviewerData {
    home: ReviewerDataStep;
    step2: ReviewerDataStep;
    step3: ReviewerDataStep;
    step4: ReviewerDataStep;
}
export interface IAllDocumentsData {
    home: Array<any>;
    step2: Array<any>;
    step4: Array<any>;
}
export type UploadFunctionProp = {
    data: any;
    docType: string;
};

export interface ITimeNotification {
    message: string;
    time: number;
    variant: VariantChose;
}

export interface SavedObligationData {
    Reviewer: string;
    Submit: string;
    User: string;
    User_ID: string;
    citation: string;
    comments: string;
    draftobg: IObligations[];
    filesize: number;
    regulation_id: string;
    regulation_name: string;
    regulation_type: string;
    run_id: string;
    source_url: string;
    status: string;
}

export interface SelectOption {
    disabled?: boolean;
    label: string;
    value: string;
}

export interface IUserProfileAttributes {
    label: string;
    testId: string;
    value: string;
}

export interface Applicable_aus {
    activity_description: string;
    au_id: number;
    au_name: string;
    confidence: 0;
    justification: string;
    status: StatusChose;
    status_to_be_reviewed: StatusChose;
}

export interface AU_Mapping {
    applicable_aus: Applicable_aus[];
    ob_id: number;
    obligation_id?: string;
    obligation_name: string;
}

export interface SavedControlMapping {
    Reviewer: string;
    Submit: string;
    User: string;
    User_ID: string;
    au_risk_control_filename: string;
    au_risk_control_filesize: number;
    aumapping: AU_Mapping[];
    citation: string;
    comments: string;
    regulation_id: string;
    regulation_name: string;
    run_id: string;
    source_url: string;
    status: string;
}

export interface Validate5ws {
    Control_Description: string;
    Control_Name: string;
    What: string;
    What_Confidence_Score: number;
    When: string;
    When_Confidence_Score: number;
    Where: string;
    Where_Confidence_Score: number;
    Who: string;
    Who_Confidence_Score: number;
    Why: string;
    Why_Confidence_Score: number;
    ctrl_id: number;
    obligation_id?: string;
    status: StatusChose;
    status_to_be_reviewed: StatusChose;
}

export interface SavedAssessControl {
    Reviewer: string;
    Submit: string;
    User: string;
    User_ID: string;
    citation: string;
    comments: string;
    regulation_id: string;
    regulation_name: string;
    run_id: string;
    source_url: string;
    status: string;
    validate5ws: Validate5ws[];
}

export type IFile = {
    name: string;
    size: number;
};
