import './timeNotification.scss';

import { useEffect, useState } from 'react';

import { ITimeNotification } from '../../../../constants/commonExportedInterfaces';
import { NexusIcon } from '@nexus/react';
import NotificationComponent from '../../nds/notification/notification';
import ProgressBar from '../../nds/progressbar/progress';
import RejectNexusIcon from '@nexus/core/dist/assets/icons/navigation/ic_close_24px.svg';

const TimeNotification: React.FC<ITimeNotification> = ({ message, variant, time }) => {
    const [show, setShow] = useState(true);
    const [animation, setAnimation] = useState(false);
    const [progress, setProgress] = useState(100);

    useEffect(() => {
        const timeId = setTimeout(() => {
            closeNotification();
        }, time);

        const progressId = setInterval(() => {
            setProgress((currentProgress) => currentProgress - 100 / (time / 1000));
        }, 1000);

        return () => {
            clearTimeout(timeId);
            clearInterval(progressId);
        };
    }, []);

    if (!show) {
        return null;
    }

    const closeNotification = () => {
        setAnimation(true);
        setTimeout(() => {
            setShow(false);
        }, 240);
    };

    return (
        <div
            className={`time-notification-outer-container ${animation ? 'remove' : 'show'}`}
            data-testid='nexus-time-notification'
        >
            <div className='time-notification-inner-container'>
                <div className='content-container'>
                    <NotificationComponent message={message} variant={variant} />
                    <NexusIcon
                        data-testid='reject-nexus-icon'
                        size='md'
                        src={RejectNexusIcon}
                        className='nexus-pr-2 close-icon'
                        onClick={closeNotification}
                    />
                </div>
                <ProgressBar value={progress} />
            </div>
        </div>
    );
};

export default TimeNotification;
