import './dashboard.scss';
import i18n from 'locales/i18n';
import ColorInfoBlock from 'app/components/reusable/colorInfoBlock/ColorInfoBlock';
import NexusBellIcon from '@nexus/core/dist/assets/icons/social/ic_notifications_none_24px.svg';
import CheckInCircleIcon from '@nexus/core/dist/assets/icons/action/ic_check_circle_24px.svg';
import LibraryBooksIcon from '@nexus/core/dist/assets/icons/av/ic_library_books_24px.svg';
import ButtonComponent from 'app/components/nds/button/button';

const Dashboard: React.FC = () => {
    return (
        <div data-testid='dashboard' className='dashboard-main-container nexus-padding-3 nexus-pt-3 nexus-pb-3'>
            <h3 className='title'>{i18n.t<string>('dashboard.title')}</h3>
            <div className='dashboard-info-blocks-container nexus-row nexus-middle-sm nexus-mb-4'>
                <ColorInfoBlock
                    iconSrc={LibraryBooksIcon}
                    info={i18n.t<string>('dashboard.inProgress')}
                    status={127}
                    color='blue'
                    classes={['nexus-mr-2']}
                />
                <ColorInfoBlock
                    iconSrc={NexusBellIcon}
                    info={i18n.t<string>('dashboard.actionRequired')}
                    status={127}
                    color='orange'
                    classes={['nexus-mr-2']}
                />
                <ColorInfoBlock
                    iconSrc={CheckInCircleIcon}
                    info={i18n.t<string>('dashboard.closed')}
                    status={127}
                    color='white'
                    classes={['nexus-mr-2']}
                />
                <ButtonComponent
                    // eslint-disable-next-line no-console
                    click={() => console.log('new case created')}
                    type={['nexus-btn-primary', 'nexus-btn-large']}
                    label={i18n.t('dashboard.newCaseBtn')}
                    extraClass='new-case-btn'
                />
            </div>
        </div>
    );
};

export default Dashboard;
