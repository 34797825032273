// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/bcg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hero-image-login {\n  background-image: linear-gradient(rgba(0, 0, 0, 0.3294117647), rgba(0, 0, 0, 0.3490196078)), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  height: 100vh;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n\n.align-middle {\n  position: absolute;\n  top: 15%;\n  width: 100%;\n}\n\n.align-middle-login {\n  position: absolute;\n  top: 10%;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/app/containers/unAuthContainer.scss"],"names":[],"mappings":"AAAA;EACI,oIAAA;EACA,aAAA;EACA,4BAAA;EACA,sBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,QAAA;EACA,WAAA;AACJ;;AAEA;EACI,kBAAA;EACA,QAAA;EACA,WAAA;AACJ","sourcesContent":[".hero-image-login {\n    background-image: linear-gradient(#00000054, #00000059), url('../assets/images/bcg.png');\n    height: 100vh;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n.align-middle {\n    position: absolute;\n    top: 15%;\n    width: 100%;\n}\n\n.align-middle-login {\n    position: absolute;\n    top: 10%;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
