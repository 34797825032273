import './noRecords.scss';

import { useEffect, useState } from 'react';

import { NexusCard } from '@nexus/react';
import { UserRole } from 'constants/commonExportedInterfaces';
import { getUserRole } from '../../../views/home/redux/homeSelectors';
import i18n from 'locales/i18n';
import { useSelector } from 'react-redux';

const NoRecordsTemplate: React.FC = () => {
    const userRole = useSelector<string>(getUserRole);
    const [noRecordsText, setNoRecordsText] = useState('');

    useEffect(() => {
        setNoRecordsText(i18n.t<string>(`noRecordsTexts.${userRole === UserRole.ANALYST ? 'analyst' : 'reviewer'}`));
    }, [userRole]);

    return (
        <NexusCard
            className={`nexus-row nexus-pt-8 nexus-pb-8 no-eq-config-records-border`}
            data-testid='nexus-records-template'
        >
            <div className='nexus-col-2xl-4 nexus-col-xl-4 nexus-col-md-3' />
            <div
                className={
                    'nexus-center-2xl nexus-center-xl nexus-center-md nexus-col-2xl-4 nexus-col-xl-4 nexus-col-md-2 nexus-subtitle'
                }
            >
                <div className='nexus-rhythm-bottom-4'>{noRecordsText}</div>
            </div>
            <div className='nexus-col-2xl-4 nexus-col-xl-4 nexus-col-md-3' />
        </NexusCard>
    );
};

export default NoRecordsTemplate;
