import './documentDataBlock.scss';

import React from 'react';

interface IDocumentDataBlock {
    label: string;
    data: string;
}

const DocumentDataBlock: React.FC<IDocumentDataBlock> = ({ label, data }) => {
    return (
        <div className='nexus-flex nexus-flex-col nexus-mr-1 doc-info'>
            <p className='bold paragraph'>{label}</p>
            <p
                className={`paragraph ${label === 'Name' && data !== 'not found' ? 'green' : null}`}
                data-testid={`nexus-data-block-${label}`}
            >
                {data}
            </p>
        </div>
    );
};

export default DocumentDataBlock;
