import './tableToAuMapping.scss';

import {
    IAllDocumentsData,
    IAuMappingData,
    ITimeNotification,
    UserRole,
    VariantChose,
} from 'constants/commonExportedInterfaces';
import {
    INewHomeObligationSliceReducer,
    IObligationTable,
    selectNewHomeObligation,
} from 'app/views/home/redux/newHomeObligation.reducer';
import { NexusCheckbox, NexusTable } from '@nexus/react';
import React, { useEffect } from 'react';
import { addNotificationData, getSavedObligation } from 'app/views/home/redux/homeActions';
import {
    getUserRole,
    retrieveAllPagesFile,
    retrieveHashValue,
    selectAuMappingToSave,
    selectNotificationsData,
} from 'app/views/home/redux/homeSelectors';

import BodyComponent from './BodyComponent';
import ButtonsComponentSelectStatus from '../reusable/buttonsComponentSelectStatus/ButtonsComponentSelectStatus';
import ChipComponentPercent from '../reusable/chipComponentPercent/ChipComponentPercent';
import ControlButtons from '../reusable/controlButtons/ControlButtons';
import DescriptionComponentModal from '../reusable/descriptionComponentModal/DescriptionComponentModal';
import { IObligationRow } from './ITableToAuMapping';
import ModalComponent from '../nds/modal/modal';
import NoRecordsTemplate from '../reusable/noRecords/NoRecordsTemplate';
import PaginationComponent from '../nds/pagination/pagination';
import TableHeaderComponent from './TableHeaderComponent';
import TitleBar from '../reusable/titleBar/TitleBar';
import i18n from 'locales/i18n';
import { limit } from 'constants/constants';
import { useAppDispatch } from 'store';
import { useDescriptionModal } from 'hooks/useDescriptionModal';
import { useSelectCheckbox } from 'hooks/useSelectCheckbox';
import { useSelectedPage } from 'hooks/useSelectedPage';
import { useSelector } from 'react-redux';

const TableToAuMapping: React.FC = () => {
    const dispatch = useAppDispatch();

    const obligations: INewHomeObligationSliceReducer = useSelector(selectNewHomeObligation);
    const auMappingToSave: IAuMappingData[] = useSelector(selectAuMappingToSave);
    const hash: number = useSelector(retrieveHashValue);
    const allPagesFiles: IAllDocumentsData = useSelector(retrieveAllPagesFile);
    const userRole: UserRole = useSelector(getUserRole);
    const notificationsData: ITimeNotification[] = useSelector(selectNotificationsData);

    const { page, selectedPage, pagesVisited } = useSelectedPage();
    const [closeDescriptionModal, descriptionModal, openDescriptionModal] = useDescriptionModal();
    const [checkedState, setCheckedState, checkedStateAll, setCheckedStateAll, handleOnChange, handleOnChangeAll] =
        useSelectCheckbox();

    const statusBy = userRole === UserRole.ANALYST ? 'obligation_status_by_analyst' : 'obligation_status_by_reviewer';

    useEffect(() => {
        if (userRole === UserRole.REVIEWER && allPagesFiles.home.length) {
            dispatch(getSavedObligation(hash));
        }
    }, [userRole]);

    useEffect(() => {
        if (!checkedState?.length) setCheckedState(new Array(obligations.obligationsToAuTable.length).fill(false));
    }, [obligations]);

    if (!auMappingToSave?.length)
        return (
            <div data-testid='nexus-table-to-au-mapping'>
                <TitleBar title={i18n.t('tableToAuMapping.titleTableToAuMapping')} />
                <NoRecordsTemplate />
            </div>
        );

    const createBodyTable = (data: IObligationTable[]) => {
        let tableBodyData = [] as IObligationRow[];

        data?.forEach(
            (
                {
                    bu_name,
                    obligation_confidence_score,
                    obligation_description,
                    obligation_id,
                    uniqueKeyID,
                    obligation_status_by_analyst,
                    obligation_status_by_reviewer,
                    obligation_title,
                }: IObligationTable,
                index: number,
            ) =>
                tableBodyData.push({
                    bu_name: bu_name,
                    indexSelect: index,
                    obligation_confidence_score: <ChipComponentPercent value={obligation_confidence_score} />,
                    obligation_description: (
                        <DescriptionComponentModal
                            description={obligation_description}
                            confidenceScore={obligation_confidence_score}
                            buName={bu_name}
                            openDescriptionModal={openDescriptionModal}
                        />
                    ),
                    obligation_id: obligation_id,
                    obligation_status_by_analyst:
                        userRole === UserRole.REVIEWER ? (
                            obligation_status_by_analyst
                        ) : (
                            <ButtonsComponentSelectStatus
                                uniqueKeyID={uniqueKeyID}
                                statusAnalyst={obligation_status_by_analyst}
                                statusReviewer={obligation_status_by_reviewer}
                            />
                        ),
                    obligation_status_by_reviewer: (
                        <ButtonsComponentSelectStatus
                            uniqueKeyID={uniqueKeyID}
                            statusAnalyst={obligation_status_by_analyst}
                            statusReviewer={obligation_status_by_reviewer}
                        />
                    ),
                    obligation_title: obligation_title,
                    select: (
                        <NexusCheckbox
                            data-testid='Check'
                            className='nexus-rhythm-2'
                            onInput={() => handleOnChange(index, checkedState, checkedStateAll, setCheckedState)}
                            checked={checkedState[index]}
                        >
                            {index}
                        </NexusCheckbox>
                    ),
                    uniqueKeyID: uniqueKeyID,
                } as IObligationRow),
        );

        return tableBodyData;
    };

    const tableData = createBodyTable(obligations.obligationsToAuTable);

    const validateTableRows = () => {
        const option =
            userRole === UserRole.REVIEWER ? 'obligation_status_by_reviewer' : 'obligation_status_by_analyst';
        const rowsStatuses: string[] = obligations.obligationsToAuTable.map((obligation) => obligation[option]);
        const valid = rowsStatuses.every((status: string) => status !== '');
        return !valid;
    };

    const handleSave = () => {
        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: `${i18n.t('generalAnswersForSavingDataCalls.success')}`,
                    time: 5000,
                    variant: VariantChose.SUCCESS,
                } as ITimeNotification,
            ]),
        );
    };

    const handleSubmit = () => {
        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: i18n.t('generalOnSubmitAnswer.success'),
                    time: 5000,
                    variant: VariantChose.SUCCESS,
                } as ITimeNotification,
            ]),
        );
    };

    return (
        <div data-testid='nexus-table-to-au-mapping'>
            <TitleBar title={i18n.t('tableToAuMapping.titleTableToAuMapping')} />

            <NexusTable type='custom'>
                <TableHeaderComponent
                    statusBy={statusBy}
                    checkedStateAll={checkedStateAll}
                    handleOnChangeAll={handleOnChangeAll}
                    checkedState={checkedState}
                    setCheckedState={setCheckedState}
                    setCheckedStateAll={setCheckedStateAll}
                />
                <BodyComponent tableData={tableData} pagesVisited={pagesVisited} statusBy={statusBy} />
                <PaginationComponent
                    selectedPage={selectedPage}
                    offset={0}
                    limit={limit}
                    size={tableData.length}
                    page={page}
                />
            </NexusTable>

            <ControlButtons submit={handleSubmit} save={handleSave} validate={validateTableRows()} />

            {descriptionModal.open && (
                <ModalComponent
                    showModal={descriptionModal.open}
                    closeModal={closeDescriptionModal}
                    headerContent={descriptionModal.header}
                    modalBodyContent={descriptionModal.description}
                    primaryBtnText={'Close'}
                    primaryBtnOnclickCallBack={closeDescriptionModal}
                    isSecondButton={false}
                    size='lg'
                />
            )}
        </div>
    );
};

export default TableToAuMapping;
