import React from 'react';
import { NexusProgressBar } from '@nexus/react';

interface Props {
    circle?: boolean;
    error?: boolean;
    indeterminate?: boolean;
    value: number;
}
const ProgressBar: React.FC<Props> = ({ value, circle, indeterminate, error }) => (
    <NexusProgressBar
        data-testid='nexus-progressbar'
        circle={circle}
        error={error}
        indeterminate={indeterminate}
        value={value}
    ></NexusProgressBar>
);

export default ProgressBar;
