import { limit } from 'constants/constants';
import { setPaginationExternally } from 'app/views/home/redux/homeActions';
import { useAppDispatch } from 'store';
import { useState } from 'react';

export function useSelectedPage() {
    const [page, setPage] = useState<number>(1);
    const dispatch = useAppDispatch();

    const pagesVisited = () => (page - 1) * limit;

    const selectedPage = (data: number) => {
        setPage(data);
        dispatch(setPaginationExternally(false));
    };

    return { page, pagesVisited, selectedPage };
}
