import './rowExpansion.scss';

import { IApplicableAus, StatusChose, UserRole } from 'constants/commonExportedInterfaces';
import { headerChildAnalyst, headerChildReviewer } from 'app/components/controlsMapping/tableHeaders';

import ApproveIcon from '../approveIcon/ApproveIcon';
import React from 'react';
import RejectIcon from '../rejectIcon/RejectIcon';
import TooltipComponent from 'app/components/nds/tooltip/tooltip';
import { getUserRole } from 'app/views/home/redux/homeSelectors';
import { getValuePercentage } from 'services/getPercentagevalue';
import { percentage } from 'constants/constants';
import { useSelector } from 'react-redux';

interface IRowExpansion {
    data: IApplicableAus[];
    detectOpenRow: string;
    handleOptionsChange: (value: StatusChose, au_name: string, detectOpenRow: string) => void;
}

const RowExpansionControlsMapping: React.FC<IRowExpansion> = ({ data, handleOptionsChange, detectOpenRow }) => {
    const userRole = useSelector(getUserRole);
    return (
        <>
            {data?.length ? (
                <>
                    <tr>
                        {(userRole === UserRole.ANALYST ? headerChildAnalyst : headerChildReviewer).map(
                            ({ id, label }) => (
                                <th key={id} data-testid={`column-header-${id}`}>
                                    {label}
                                </th>
                            ),
                        )}
                    </tr>

                    {data.map(
                        ({
                            au_id,
                            au_name,
                            justification,
                            confidence,
                            status,
                            status_to_be_reviewed,
                            activity_description,
                            ctrl_name,
                        }: IApplicableAus) => (
                            <tr key={au_name} className='border'>
                                <td data-testid='au-mapping-justification'>{justification}</td>
                                <td data-testid='au-mapping-au-id'>{au_id}</td>
                                <td data-testid='au-mapping-au-name'>{ctrl_name}</td>
                                <td className='row-width'>
                                    <TooltipComponent message={activity_description} placement='left'>
                                        <span data-testid='au-mapping-activity-description' className='ellipsis'>
                                            {activity_description}
                                        </span>
                                    </TooltipComponent>
                                </td>
                                <td data-testid='au-mapping-confidence'>
                                    {getValuePercentage(confidence)} &nbsp;{percentage}
                                </td>
                                {userRole === UserRole.REVIEWER && (
                                    <td className='row-width'>
                                        <span data-testid='analyst_status'>{status_to_be_reviewed}</span>
                                    </td>
                                )}
                                <td className='row-width'>
                                    <div className='nexus-row'>
                                        <ApproveIcon
                                            status={userRole === UserRole.REVIEWER ? status : status_to_be_reviewed}
                                            handleApproveIcon={() =>
                                                handleOptionsChange(StatusChose.APPROVED, au_name, detectOpenRow)
                                            }
                                        />
                                        <RejectIcon
                                            status={userRole === UserRole.REVIEWER ? status : status_to_be_reviewed}
                                            handleRejectIcon={() =>
                                                handleOptionsChange(StatusChose.REJECTED, au_name, detectOpenRow)
                                            }
                                        />
                                    </div>
                                </td>
                            </tr>
                        ),
                    )}
                </>
            ) : (
                <div data-testid='row-extension-empty' />
            )}
        </>
    );
};
export default RowExpansionControlsMapping;
