import './table.scss';

import React, { useState } from 'react';

import { NexusIcon } from '@nexus/react';
import SORT_ICON from '@nexus/core/dist/assets/icons/content/ic_sort_24px.svg';

interface InputProps {
    column: {
        [id: string]: string;
    }[];
    row: {
        data: string[];
    }[];
    sort?: boolean;
    onSortChange?: (data?: any, index?: any, type?: string) => void;
}
const Table: React.FC<InputProps> = (props: InputProps) => {
    const [sort, setSort] = useState(false);
    const [activeIndex, setActiveIndex] = React.useState(-1);
    const onSortClick = (
        data: {
            [id: string]: string;
        },
        index: number,
    ) => {
        setActiveIndex(index);
        let sortType = sort ? 'asc' : 'desc';
        if (props.onSortChange) {
            setSort(!sort);
            props.onSortChange(data, index, sortType);
        }
    };

    return (
        <>
            <table className='nexus-table' data-testid='nexus-table'>
                <thead>
                    <tr>
                        {props.column.map((data: any, idx: number) => (
                            <th key={idx}>
                                {data.key}
                                {props.sort && props.onSortChange ? (
                                    <span className={activeIndex === idx ? 'active' : 'inactive'}>
                                        <NexusIcon
                                            data-testid='nexus-icon-in-table'
                                            src={SORT_ICON}
                                            onClick={() => onSortClick(data, idx)}
                                            style={sort && activeIndex === idx ? { transform: 'rotate(180deg)' } : {}}
                                        ></NexusIcon>
                                    </span>
                                ) : null}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.row.map((rowdata: any, idx: number) => (
                        <tr key={idx}>
                            {rowdata.data.map((coloumdata: any, idx2: number) => (
                                <td key={idx2}>{coloumdata}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default Table;
