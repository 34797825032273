import './assessControlTable.scss';

import { SavedAssessControl, StatusChose, UserRole, Validate5ws } from 'constants/commonExportedInterfaces';
import {
    addNotificationData,
    getSavedAssessControl,
    saveAnalystAssessControl,
    setPaginationExternally,
    updateStatusInAssessControlsToSave,
} from '../../views/home/redux/homeActions';
import {
    getUserRole,
    retrieveAllPagesFile,
    retrieveHashValue,
    selectAssessControlsToSave,
    selectNotificationsData,
} from '../../views/home/redux/homeSelectors';
import { headerAnalyst, headerReviewer } from './tableHeaderAssessControl';
import { useEffect, useState } from 'react';

import ApproveIcon from '../reusable/approveIcon/ApproveIcon';
import ControlButtons from '../reusable/controlButtons/ControlButtons';
import { IAssessControls } from 'constants/commonExportedInterfaces';
import { NexusIcon } from '@nexus/react';
import NoRecordsTemplate from 'app/components/reusable/noRecords/NoRecordsTemplate';
import PaginationComponent from '../nds/pagination/pagination';
import RejectIcon from '../reusable/rejectIcon/RejectIcon';
import TitleBar from '../reusable/titleBar/TitleBar';
import TooltipComponent from '../nds/tooltip/tooltip';
import check24px from '@nexus/core/dist/assets/icons/navigation/ic_check_24px.svg';
import close24px from '@nexus/core/dist/assets/icons/navigation/ic_close_24px.svg';
import i18n from 'locales/i18n';
import { limit } from '../../../constants/constants';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';

const AssessControlTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const assessControlsToSave = useSelector(selectAssessControlsToSave);
    const userRole = useSelector(getUserRole);
    const notificationsData = useSelector(selectNotificationsData);
    const hash = useSelector(retrieveHashValue);
    const [page, setPage] = useState<number>(1);
    const selectedPage = (data: number) => {
        setPage(data);

        dispatch(setPaginationExternally(false));
    };
    const pagesVisited = () => (page - 1) * limit;
    const allPagesFiles = useSelector(retrieveAllPagesFile);

    useEffect(() => {
        setPage(1);
        if (userRole === UserRole.REVIEWER && allPagesFiles.step4.length) {
            dispatch(getSavedAssessControl(hash));
        }
    }, [userRole]);

    const decideOnIcon = (value: string) => {
        return (
            <TooltipComponent allow-click='false' placement='left' message={value}>
                <NexusIcon
                    data-testid={value === 'None' ? 'assess-control-icon-cross' : 'assess-control-icon-check'}
                    className={value === 'None' ? 'icon-cross' : 'icon-check'}
                    src={value === 'None' ? close24px : check24px}
                ></NexusIcon>
            </TooltipComponent>
        );
    };

    const handleOptionsChange = (value: StatusChose, ctrl_id: number) => {
        dispatch(updateStatusInAssessControlsToSave({ ctrl_id, status: value, user: userRole }));
    };

    const validateTableRows = () => {
        const option = userRole === UserRole.REVIEWER ? 'status' : 'status_to_be_reviewed';
        const rowsStatuses = assessControlsToSave.map(
            (control: Validate5ws) => control[option as keyof typeof control],
        );
        const valid = rowsStatuses.every((status: string) => status !== '');
        return !valid;
    };

    const handleSave = () => {
        // TODO SavedAssessControl must be filled

        const body: SavedAssessControl = {
            Reviewer: 'string',
            Submit: 'string',
            User: 'string',
            User_ID: 'string',
            citation: 'string',
            comments: 'string',
            regulation_id: 'string',
            regulation_name: 'string',
            run_id: hash,
            source_url: 'string',
            status: 'string',
            validate5ws: assessControlsToSave,
        };

        dispatch(saveAnalystAssessControl(body));
    };

    const handleSubmit = () => {
        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: i18n.t('generalOnSubmitAnswer.success'),
                    time: 5000,
                    variant: 'success',
                },
            ]),
        );
    };

    return (
        <div data-testid='nexus-assess-control-table'>
            <TitleBar title={i18n.t('assessControlTable.titleAccessControl')} />
            {assessControlsToSave?.length ? (
                <>
                    <table className='nexus-table'>
                        <thead>
                            <tr>
                                {(userRole === UserRole.ANALYST ? headerAnalyst : headerReviewer).map(
                                    ({ fieldId, label }) => (
                                        <th key={fieldId} data-testid={`column-header-${fieldId}`}>
                                            {label}
                                        </th>
                                    ),
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {assessControlsToSave
                                ?.slice(pagesVisited(), pagesVisited() + limit)
                                .map(
                                    ({
                                        ctrl_id,
                                        Control_Name,
                                        Control_Description,
                                        What,
                                        When,
                                        Who,
                                        Where,
                                        Why,
                                        status,
                                        status_to_be_reviewed,
                                    }: IAssessControls) => (
                                        <tr key={ctrl_id} data-testid={`row-${ctrl_id}}`}>
                                            <td className='row-width' data-testid='nexus-control-name-row'>
                                                {Control_Name}
                                            </td>
                                            <td className='row-width'>
                                                <TooltipComponent
                                                    allow-click='false'
                                                    placement='left'
                                                    message={Control_Description}
                                                >
                                                    <span
                                                        data-testid='nexus-control-description-row'
                                                        className='ellipsis'
                                                    >
                                                        {Control_Description}
                                                    </span>
                                                </TooltipComponent>
                                            </td>
                                            {userRole === UserRole.REVIEWER && (
                                                <td className='row-width'>
                                                    <span data-testid='analyst_status'>{status_to_be_reviewed}</span>
                                                </td>
                                            )}
                                            <td className='row-width'>
                                                <div className='nexus-row'>
                                                    <ApproveIcon
                                                        status={
                                                            userRole === UserRole.REVIEWER
                                                                ? status
                                                                : status_to_be_reviewed
                                                        }
                                                        handleApproveIcon={() =>
                                                            handleOptionsChange(StatusChose.APPROVED, ctrl_id)
                                                        }
                                                    />
                                                    <RejectIcon
                                                        status={
                                                            userRole === UserRole.REVIEWER
                                                                ? status
                                                                : status_to_be_reviewed
                                                        }
                                                        handleRejectIcon={() =>
                                                            handleOptionsChange(StatusChose.REJECTED, ctrl_id)
                                                        }
                                                    />
                                                </div>
                                            </td>
                                            <td className='row-width' data-testid='nexus-what-row'>
                                                {decideOnIcon(What)}
                                            </td>
                                            <td className='row-width' data-testid='nexus-when-row'>
                                                {decideOnIcon(When)}
                                            </td>
                                            <td className='row-width' data-testid='nexus-who-row'>
                                                {decideOnIcon(Who)}
                                            </td>
                                            <td className='row-width' data-testid='nexus-where-row'>
                                                {decideOnIcon(Where)}
                                            </td>
                                            <td className='row-width' data-testid='nexus-why-row'>
                                                {decideOnIcon(Why)}
                                            </td>
                                        </tr>
                                    ),
                                )}
                        </tbody>
                    </table>
                    <PaginationComponent
                        offset={0}
                        limit={limit}
                        size={assessControlsToSave?.length}
                        selectedPage={selectedPage}
                        page={page}
                    />
                    <ControlButtons submit={handleSubmit} save={handleSave} validate={validateTableRows()} />
                </>
            ) : (
                <NoRecordsTemplate />
            )}
        </div>
    );
};

export default AssessControlTable;
