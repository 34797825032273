import './approveIcon.scss';

import ApproveNexusIcon from '@nexus/core/dist/assets/icons/navigation/ic_check_24px.svg';
import { NexusIcon } from '@nexus/react';
import { StatusChose } from 'constants/commonExportedInterfaces';

interface IApproveIcon {
    handleApproveIcon: () => void;
    status?: string;
}
const ApproveIcon = ({ handleApproveIcon, status }: IApproveIcon) => {
    return (
        <>
            <div data-testid='approve-icon'>
                <NexusIcon
                    data-testid='approve-nexus-icon'
                    size='md'
                    src={ApproveNexusIcon}
                    className={`button-style nexus-ml-2 ${
                        status === StatusChose.APPROVED ? 'approve-button-style' : 'reject-button-unselected'
                    }`}
                    onClick={handleApproveIcon}
                />
            </div>
        </>
    );
};
export default ApproveIcon;
