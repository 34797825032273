import * as ACTIONS from './homeActions';

import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { get, post } from 'utils/fetch';

import { IAuMappingData } from 'constants/commonExportedInterfaces';
import { REACT_APP_BASE_URL } from 'config/settings';
import { RootState } from 'store';
import i18n from 'locales/i18n';

/** newHomeObligationTestData.ts */

const newApiResponseBodyPostUploadAFile = {
    content_type: 'string',
    file_name: 'string',
    size: 'string',
    status: 'string',
};

const newApiDataInterface = [
    {
        hasAssessmentUnits: [
            {
                bu_code: 'string',
                bu_description: 'string',
                bu_id: 'string',
                bu_name: 'string',
                full_path: 'string',
                processes: [
                    {
                        controls: [
                            {
                                control_code: 'string',
                                control_description: 'string',
                                control_id: 'string',
                                control_level: 'string',
                                control_maturity: 'string',
                                control_name: 'string',
                                control_status: 'string',
                                control_type: 'string',
                            },
                        ],
                        process_code: 'string',
                        process_description: 'string',
                        process_id: 'string',
                        process_name: 'string',
                        risks: [
                            {
                                risk_code: 'string',
                                risk_description: 'string',
                                risk_id: 'string',
                                risk_level: {},
                                risk_name: 'string',
                                risk_rating: {},
                                risk_status: {},
                            },
                        ],
                    },
                ],
                sector: 'string',
                sub_bu_code: 'string',
                sub_bu_description: 'string',
                sub_bu_name: 'string',
            },
        ],
        obligation_confidence_score: 0,
        obligation_description: 'string',
        obligation_id: 'string',
        obligation_index: 'string',
        obligation_status_by_analyst: 'string',
        obligation_status_by_reviewer: 'string',
        obligation_title: 'string',
        obligation_type: 'string',
    },
];

/** newHomeObligationInterfaces.ts */

interface IFileUploadResponse {
    content_type: string;
    file_name: string;
    size: string;
    status: string;
}
interface IObligation {
    obligation_id: string;
    obligation_index: string;
    obligation_title: string;
    obligation_description: string;
    obligation_type: string;
    hasAssessmentUnits: IAssessmentUnit[];
    obligation_status_by_analyst: string;
    obligation_status_by_reviewer: string;
    obligation_confidence_score: number;
}

interface IAssessmentUnit {
    bu_id: string;
    bu_code: string;
    bu_name: string;
    bu_description: string;
    sub_bu_code: string;
    sub_bu_name: string;
    sub_bu_description: string;
    sector: string;
    full_path: string;
    processes: IProcess[];
}

interface IProcess {
    process_id: string;
    process_code: string;
    process_name: string;
    process_description: string;
    risks: IRisk[];
    controls: IControl[];
}

interface IRisk {
    risk_id: string;
    risk_code: string;
    risk_name: string;
    risk_description: string;
    risk_status: {};
    risk_rating: {};
    risk_level: {};
}

interface IControl {
    control_id: string;
    control_code: string;
    control_name: string;
    control_description: string;
    control_type: string;
    control_maturity: string;
    control_status: string;
    control_level: string;
}

export interface INewHomeObligationSliceReducer {
    fileUploadResponse: {};
    obligations: IObligation[];
    status: boolean;
    obligationsToAuTable: IObligationTable[];
}

interface IAuObligationStatus {
    uniqueKeyID: string;
    value: string;
    statusBy: string;
}

export interface IObligationTable {
    obligation_id: number;
    obligation_title: string;
    obligation_description: string;
    bu_name: string;
    obligation_confidence_score: number;
    obligation_status_by_analyst: string;
    obligation_status_by_reviewer: string;
    uniqueKeyID: string;
}

const createObligationsToAuTable = (response: IAuMappingData[]): IObligationTable[] => {
    const tableAuObligation = [] as IObligationTable[];

    for (let i = 0; i < response.length; i++) {
        const element = response[i];

        for (let j = 0; j < element.applicable_aus.length; j++) {
            tableAuObligation.push({
                bu_name: element.applicable_aus[j].au_name,
                obligation_confidence_score: element.applicable_aus[j].confidence,
                obligation_description: element.applicable_aus[j].activity_description,
                obligation_id: element.ob_id,
                obligation_status_by_analyst: '',
                obligation_status_by_reviewer: '',
                obligation_title: element.obligation_name,
                uniqueKeyID: `${element.ob_id} ${element.applicable_aus[j].au_id}`,
            });
        }
    }

    return tableAuObligation;
};

/** newHomeObligationAction.ts */

export const postUploadAFile = createAsyncThunk(
    'ObligationController/UploadAFile',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await post({
                // baseURL: `${REACT_APP_BASE_URL}/uploadFile`,
                baseURL: `https://nexus4risk-backend-rcm-dev.sbp.eyclienthub.com/ui-service/v1/uploadFile`,
                // data: payload.data,
                data: 'required_obligationFile_string',
                timeout: 1000000,
            });
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const getAllObligations = createAsyncThunk('ObligationController/GetAllObligations', async () => {
    const response = await get({ baseURL: `${REACT_APP_BASE_URL}/obligations` });
    return response;
});

export const updateStatusInObligationsToAuTable = createAction<IAuObligationStatus>(
    'ObligationController/updateStatusInObligationsToAuTable',
);

/** newHomeObligation.reducer.ts */

const INITIAL_STATE_NEW_HOME_OBLIGATION: INewHomeObligationSliceReducer = {
    fileUploadResponse: { ...newApiResponseBodyPostUploadAFile } as IFileUploadResponse,
    obligations: [...newApiDataInterface] as IObligation[],
    obligationsToAuTable: [] as IObligationTable[],
    status: false,
};

type NewHomeObligation = Readonly<typeof INITIAL_STATE_NEW_HOME_OBLIGATION>;

const newHomeObligation = createSlice({
    extraReducers: (builder) => {
        builder
            /** 2 use when newAPI ready */
            // todo newApi https://nexus4risk-backend-rcm-dev.sbp.eyclienthub.com/ui-service/v1/swagger-ui/index.html#/
            .addCase(getAllObligations.pending, (state) => {
                state.status = true;
            })
            /** 1 get OLD AU */
            .addCase(ACTIONS.uploadAuMappingFileToBackend.fulfilled, (state, action) => {
                return {
                    ...state,
                    obligationsToAuTable: createObligationsToAuTable(action.payload?.data.result),
                };
            })
            .addCase(updateStatusInObligationsToAuTable, (state, action) => {
                return {
                    ...state,
                    obligationsToAuTable: [
                        ...state.obligationsToAuTable.map((obligation) => {
                            if (obligation.uniqueKeyID !== `${action.payload.uniqueKeyID}`) return obligation;
                            return {
                                ...obligation,
                                [action.payload.statusBy]: action.payload.value,
                            };
                        }),
                    ],
                };
            });
    },
    initialState: INITIAL_STATE_NEW_HOME_OBLIGATION as NewHomeObligation,
    name: 'newHomeObligation',
    reducers: {},
});

export default newHomeObligation.reducer;
/** newHomeObligationSelectors */
export const selectNewHomeObligation = createSelector(
    (state: RootState) => state.newHomeObligation,
    (newHomeObligation) => newHomeObligation ?? i18n.t('noData'),
);
