import { SelectOption, StatusChose } from './commonExportedInterfaces';

import i18n from 'locales/i18n';

export enum routeNames {
    Home = 'Home',
    Profile = 'Profile',
    Logout = 'Logout',
}

export const UploadComponentType = 'UploadComponentType';
export const asic = 'asic';
export const cfr = 'cfr';
export const generic = 'generic';
export const limit = 10;
export const percentage = '%';

export const ASIC = i18n.t<string>('uploadComponent.asic');
export const CFR = i18n.t<string>('uploadComponent.cfr');
export const Clear = i18n.t<string>('uploadComponent.clear');
export const DefaultSelect = i18n.t<string>('uploadComponent.defaultSelect');
export const Generic = i18n.t<string>('uploadComponent.generic');
export const ProcessAUs = i18n.t<string>('uploadComponent.processAUs');
export const ProcessRegulations = i18n.t<string>('uploadComponent.processRegulations');
export const Upload = i18n.t<string>('uploadComponent.upload');
export const UploadRegulation = i18n.t<string>('uploadComponent.uploadRegulation');
export const DefaultSelectDocumentType = i18n.t<string>('uploadComponent.defaultSelectDocumentType');
export const DefaultSelectProcessType = i18n.t<string>('uploadComponent.defaultCategoryType');
export const UploadedDocumentText = i18n.t<string>('obligationSummary.uploadedDocumentText');

export const categoryDropdownObligation: SelectOption[] = [
    { value: 'ProcessRegulations', label: i18n.t('uploadComponent.processRegulations') },
    // { value: 'ProcessPolicy', label: i18n.t('uploadComponent.processPolicy') },
];

export const categoryDropdownObligationRegulatory: SelectOption[] = [
    { value: 'ProcessRegulations', label: i18n.t('uploadComponent.processRegulations') },
];

export const categoryDropdownObligationPolicy: SelectOption[] = [
    { value: 'ProcessPolicy', label: i18n.t('uploadComponent.processPolicy') },
];

export const categoryDropdownObligationProcedure: SelectOption[] = [
    { value: 'ProcessProcedure', label: i18n.t('uploadComponent.processProcedure') },
];

export const categoryDropdownAUMapping: SelectOption[] = [
    { value: 'ProcessAUs', label: i18n.t('uploadComponent.processAUs') },
];

export const selectValues = [
    { disabled: false, label: i18n.t('obligationSummary.approve'), value: StatusChose.APPROVED },
    { disabled: false, label: i18n.t('obligationSummary.reject'), value: StatusChose.REJECTED },
];

export const fileTypeDropdown: SelectOption[] = [
    // { value: 'CFR', label: i18n.t('obligationSummary.cfr') },
    // { value: 'ASIC', label: i18n.t('obligationSummary.asic') },
    // { value: 'Generic', label: i18n.t('obligationSummary.generic') },
    { value: 'Regulatory', label: i18n.t('obligationSummary.regulatory') },
    { value: 'Policy', label: i18n.t('obligationSummary.policy') },
    { value: 'Procedure', label: i18n.t('obligationSummary.procedure') },
];

export const uploadComponentHeaders = {
    home: 'Upload Document',
    step2: 'Upload Document',
    step4: 'Upload Document',
};

export const dashboardPath = '/dashboard';
export const hopePath = '/home';
