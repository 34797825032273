import { AnyAction, ThunkDispatch, configureStore } from '@reduxjs/toolkit';

import logger from 'redux-logger';
import { persistStore } from 'redux-persist';
import rootReducer from './reducers';
import { setStore } from './storeProxy';
import { useDispatch } from 'react-redux';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(logger),
});

export const getStoreWithState = (preloadedState: {}) => {
    return configureStore({
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
        preloadedState,
        reducer: rootReducer,
    });
};

/** Initializes store within core library for request interceptors and messaging */
setStore(store);

export const persistor = persistStore(store);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppDispatchThunk = ThunkDispatch<RootState, void, AnyAction>;

export const useAppDispatchThunk = () => useDispatch<AppDispatchThunk>();

export const useAppDispatch = () => useDispatch<AppDispatch>();
