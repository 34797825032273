import './overallSkeletonPane.scss';

import { ITimeNotification } from '../../../constants/commonExportedInterfaces';
import { NexusCard } from '@nexus/react';
import { ReactNode } from 'react';
import TimeNotification from '../../components/reusable/timeNotification/TimeNotification';
import { selectNotificationsData } from '../home/redux/homeSelectors';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { clearNotifications } from '../home/redux/homeActions';

type Props = {
    user?: ReactNode;
    navigation?: ReactNode;
    operationsTop?: ReactNode;
    operationsBottom?: ReactNode;
};

// const OverallSkeletonPane: React.FC<Props> = ({ user, navigation, operationsTop, operationsBottom }) => {
const OverallSkeletonPane: React.FC<Props> = ({ navigation, operationsTop, operationsBottom }) => {
    const notificationsData = useSelector(selectNotificationsData);
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearNotifications());
        };
    }, []);

    return (
        <div className='nexus-row main-container extended-container-bug-fix' data-testid='nexus-overall-skeleton-pane'>
            {notificationsData.length > 0 && (
                <div className='notifications-container' data-testid='notifications-container'>
                    {notificationsData.map((notification: ITimeNotification, index: string) => (
                        <TimeNotification
                            key={index}
                            message={notification.message}
                            variant={notification.variant}
                            time={notification.time}
                        />
                    ))}
                </div>
            )}
            {/* <div className='nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-col-sm-4 nexus-col-md-3 nexus-col-lg-4 nexus-col-xl-4 nexus-col-2xl-4 left-box nexus-p-1'>
                <NexusCard className='left-box__user-card'>{user}</NexusCard>
            </div> */}
            <div className='nexus-col-2xs-2 nexus-col-xs-4 nexus-col-sm-6 nexus-col-sm-6 nexus-col-md-8 nexus-col-lg-10 nexus-col-xl-12 nexus-col-2xl-14 right-box nexus-flex nexus-flex-col nexus-pt-1 nexus-pb-1 nexus-pr-1'>
                <NexusCard className='nexus-rhythm-bottom-1'>{navigation}</NexusCard>
                <NexusCard className='nexus-flex nexus-flex-col nexus-flex-grow'>
                    <div className='nexus-rhythm-bottom-2'>{operationsTop}</div>
                    <div className='nexus-flex-grow'>{operationsBottom}</div>
                </NexusCard>
            </div>
        </div>
    );
};

export default OverallSkeletonPane;
