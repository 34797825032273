import React from 'react';
import './customTooltip.scss';
import close24px from '@nexus/core/dist/assets/icons/navigation/ic_close_24px.svg';
import { NexusIcon } from '@nexus/react';
interface ToolTipProps {
    message: string;
    displayText: string;
    className?: string;
}
const CustomTooltip: React.FC<ToolTipProps> = ({ message, displayText, className }) => {
    const [showTooltip, setShowToolTip] = React.useState(false);
    const [hovering, setHovering] = React.useState(false);
    const [hoverInfo, setHoverInfo] = React.useState<any>({ x: 0, y: 0 });
    const onHover = (e: React.MouseEvent) => {
        !showTooltip && setHoverInfo({ x: e.clientX, y: e.clientY });
        setHovering(true);
    };

    return (
        <div className={className}>
            <span
                data-testid='obligation-summary-highlighted-text'
                className='trigger-text'
                onMouseMove={onHover}
                onClick={(e) => {
                    e.preventDefault();
                    setShowToolTip(!showTooltip);
                }}
                onMouseOut={() => {
                    !showTooltip && setHoverInfo(null);
                    setHovering(false);
                }}
            >
                {displayText}
            </span>
            {(showTooltip || hovering) && (
                <div className='tooltip' style={{ left: hoverInfo.x - 300, top: hoverInfo.y + 200 }}>
                    <div className='arrow'></div>
                    <div className='tool'>
                        {showTooltip ? (
                            <span
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowToolTip(!showTooltip);
                                }}
                            >
                                <NexusIcon className='close-button' src={close24px} />
                            </span>
                        ) : null}
                        <div className='table' dangerouslySetInnerHTML={{ __html: message }}></div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default CustomTooltip;
