import * as React from 'react';

import { NexusChip } from '@nexus/react';

interface Props {
    title: string;
    disabled?: boolean;
    error?: boolean;
    removable?: boolean;
    success?: boolean;
    contentStyles?: string;
}

const ChipComponent: React.FC<Props> = ({ title, disabled, error, removable, success, contentStyles }) => {
    return (
        <NexusChip data-testid='nexus-chip' disabled={disabled} success={success} removable={removable} error={error}>
            <span className={contentStyles}>{title}</span>
        </NexusChip>
    );
};

export default ChipComponent;
