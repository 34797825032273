import React from 'react';
import './profile.scss';
import Table from '../../components/nds/table/table';
import { useSelector } from 'react-redux';
import { selectAuthEmail, retrieveUserName } from '../../../auth';
import img from './../../assets/images/male-user-profile.png';

const ProfilePage = () => {
    const email = useSelector(selectAuthEmail);
    const fullName = useSelector(retrieveUserName);
    let profileData = [{ data: ['Name ', fullName] }, { data: ['E-mail ', email] }, { data: ['Phone ', ''] }];
    return (
        <div className='nexus-container nexus-body'>
            <div className='nexus-row'>
                <div className='nexus-col-sm-1 nexus-col-sm-offset-1 nexus-col-md-offset-2 nexus-col-md-2'>
                    <img className='profile-profile-pic' src={img} alt={fullName} />
                </div>
                <div className='nexus-col-sm-4 nexus-col-md-4 table-bg'>
                    <div className='nexus-h3'>{fullName}</div>

                    <Table column={[]} row={profileData}></Table>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
