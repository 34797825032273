import './titleBar.scss';

import React from 'react';

/**
 * TitleBar Component
 * @param title use to display title inside the component
 */
interface ITitleBar extends React.HtmlHTMLAttributes<HTMLDivElement> {
    title: string;
}

const TitleBar: React.FC<ITitleBar> = React.memo(({ title, ...restProps }: ITitleBar) => {
    return (
        <div data-testid='title-bar' className='nexus-col nexus-h4 card-header-styles nexus-p-2' {...restProps}>
            {title}
        </div>
    );
});

export default TitleBar;
