// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/bcg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hero-image {\n  background-image: linear-gradient(rgba(0, 0, 0, 0.3294117647), rgba(0, 0, 0, 0.3490196078)), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  min-height: calc(100vh - 81px);\n  background-repeat: no-repeat;\n  background-size: cover;\n}", "",{"version":3,"sources":["webpack://./src/app/containers/container.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACI,oIAAA;EACA,8BCJU;EDKV,4BAAA;EACA,sBAAA;AADJ","sourcesContent":["@import 'src/scss/variables';\n\n.hero-image {\n    background-image: linear-gradient(#00000054, #00000059), url('../assets/images/bcg.png');\n    min-height: $body-height;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n","$body-height: calc(100vh - 81px);\n\n// RWD pixel breakpoints\n\n$mobile-2xs-max: 319px;\n$mobile-xs-min: 320px;\n$mobile-xs-max: 575px;\n$tablet-sm-min: 576px;\n$tablet-sm-max: 767px;\n$tablet-md-min: 768px;\n$tablet-md-max: 1023px;\n$desktop-lg-min: 1024px;\n$desktop-lg-max: 1279px;\n$desktop-xl-min: 1280px;\n$desktop-xl-max: 1439px;\n$desktop-2xl-min: 1440px;\n$nexus-mobile: 'screen and (max-width: #{$mobile-xs-max})';\n$nexus-tablet: 'screen and (max-width: #{$tablet-md-max})';\n$nexus-desktop: 'screen and (min-width: #{$desktop-lg-min})';\n\n// nexus px measurement\n$nexus-px-msm-0: 0;\n$nexus-px-msm-1: 8px;\n$nexus-px-msm-2: 18px;\n$nexus-px-msm-3: 24px;\n$nexus-px-msm-4: 32px;\n$nexus-px-msm-5: 64px;\n$nexus-px-msm-6: 80px;\n$nexus-px-msm-7: 104px;\n$nexus-px-msm-8: 120px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
