import { IObligationTable } from 'app/views/home/redux/newHomeObligation.reducer';
import React from 'react';
import { obligationsHeaderColumnDefinition } from './tableHeaders';

interface ITableAuObligationRow {
    obligation: IObligationTable | any;
    statusBy: string;
}

const TableAuObligationRow: React.FC<ITableAuObligationRow> = ({ obligation, statusBy }) => {
    return (
        <tr>
            {Object.values(obligationsHeaderColumnDefinition(statusBy)).map((ele: any) => (
                <td key={ele?.field}>{obligation[ele?.field]}</td>
            ))}
        </tr>
    );
};

export default TableAuObligationRow;
